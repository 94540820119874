<template>
  <v-row>
    <v-col cols="12" class="text-center font-weight-bold">
      Towing information
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="model.pickup_datetime"
        type="datetime-local"
        label="Pickup date and time"
        :error-messages="errors?.pickup_datetime"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field
        v-model="model.delivery_datetime"
        type="datetime-local"
        label="Delivery date and time"
        :error-messages="errors?.delivery_datetime"
      />
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        v-bind="towDriverAttrs"
        :error-messages="errors?.tow_driver_id"
        label="Tow driver"
        clearable
        v-on="towDriverListener"
      />
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        v-bind="truckAttrs"
        :error-messages="errors?.tow_vehicle_id"
        label="Truck"
        clearable
        v-on="truckListener"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import { usePersonAutocomplete } from '@/composables/autocomplete/person-autocomplete.js';
import { useVehicleAutocomplete } from '@/composables/autocomplete/vehicle-autocomplete';
import { stepProps } from '@/composables/task/stepOptions';
import { watch } from 'vue';

defineProps({
  ...stepProps,
});

const { attrs: towDriverAttrs, listeners: towDriverListener } =
  usePersonAutocomplete({ minSearchLength: 1 });

const model = defineModel({ required: true, type: Object });

watch(
  () => towDriverAttrs.modelValue,
  (value) => {
    if (value) {
      model.value.tow_driver_id = value;
    }
  }
);

const { attrs: truckAttrs, listeners: truckListener } = useVehicleAutocomplete({
  minSearchLength: 1,
});

watch(
  () => truckAttrs.modelValue,
  (value) => {
    if (value) {
      model.value.tow_vehicle_id = value;
    }
  }
);
</script>
