import { reactive } from 'vue';

/**
 * @param {callback} callback
 * @param {number} wait
 * @param {boolean} immediate
 * @returns {Function}
 */
function debounce(callback, wait, immediate = false) {
  /** @type {number} timeout */
  var timeout;

  return function () {
    var context = this;
    var args = arguments;

    clearTimeout(timeout);

    if (immediate && !timeout) {
      callback(args, context);
    }

    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) {
        callback(args, context);
      }
    }, wait);
  };
}

/**
 * @param {object} config
 * @param {callback} config.request
 * @param {number} [config.debounceTime]
 * @returns {{debounceRequest: Function, abortSignal: AbortController}}
 */
export default function (config = {}) {
  let abortSignal = {};

  const immediateRequest = () => {
    abortSignal?.abort && abortSignal.abort();
    abortSignal = new AbortController();

    config.request(abortSignal);
  };

  const debounceRequest = debounce(([value]) => {
    immediateRequest(value);
  }, config.debounceTime || 500);

  return {
    debounceRequest,
    abortSignal,
    immediateRequest,
  };
}
