import axios from '../../plugins/axios.js';

/**
 * @param {number} department_id
 * @param {object} search
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @return {Promise<Object>}
 */
export const getDepartmentAuthorizer = (
  department_id,
  search,
  config = undefined
) => {
  return axios
    .get(`/api/v1/departments/${department_id}/authorizers`, {
      ...config,
      params: { search },
    })
    .then((response) => response.data.data);
};
