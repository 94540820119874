<template>
  <v-row>
    <v-col cols="12">
      <v-alert v-if="model.inspection_id" type="info">
        Inspection has been done.
      </v-alert>
      <v-alert v-else type="warning">
        To proceed, you need to do an inspection of the vehicle.
        <br />
        In the impound info card, click on the three vertical dots button and
        select "Inspection".
      </v-alert>
    </v-col>
  </v-row>
</template>
<script setup>
import { stepProps } from '@/composables/task/stepOptions.js';
import { impound } from '@/store/storage/impound.js';

defineProps({
  ...stepProps,
});

const model = defineModel({ required: true, type: Object });
model.value.inspection_id = impound.inspection_id;
</script>
