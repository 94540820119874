import axios from '../../plugins/axios.js';

/**
 * @param {object} vehicle
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const createVehicle = (vehicle, config = undefined) => {
  return axios
    .post('/api/v1/vehicles', vehicle, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} vehicle_id
 * @param {object} vehicle
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const updateVehicle = (vehicle_id, vehicle, config = undefined) => {
  return axios
    .patch('/api/v1/vehicles/' + vehicle_id, vehicle, config)
    .then((response) => response.data.data);
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getUsedVehicleMakes = (config = undefined) => {
  return axios
    .get('/api/v1/vehicles/used/makes', config)
    .then((response) => response.data);
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getVehicleMakes = (config = undefined) => {
  return axios
    .get('/api/v1/vehicles/makes', config)
    .then((response) => response.data.data);
};

/**
 * @param {number} make
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getUsedVehicleModels = (make, config = undefined) => {
  return axios
    .get(`/api/v1/vehicles/used/makes/${make}/models`, config)
    .then((response) => response.data);
};

/**
 * @param {number} make
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getVehicleModels = (make, config = undefined) => {
  return axios
    .get(`/api/v1/vehicles/makes/${make}/models`, config)
    .then((response) => response.data.data);
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getUsedVehicleTypes = (config = undefined) => {
  return axios
    .get('/api/v1/vehicles/used/types', config)
    .then((response) => response.data);
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getVehicleTypes = (config = undefined) => {
  return axios
    .get('/api/v1/vehicles/types', config)
    .then((response) => response.data);
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getVehicleGearboxes = (config = undefined) => {
  return axios
    .get('/api/v1/vehicles/gearboxes', config)
    .then((response) => response.data);
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getVehicleFuelTypes = (config = undefined) => {
  return axios
    .get('/api/v1/vehicles/fuel-types', config)
    .then((response) => response.data);
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getVehicleColors = (config = undefined) => {
  return axios
    .get('/api/v1/vehicles/colors', config)
    .then((response) => response.data.data);
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getUsedVehicleColors = (config = undefined) => {
  return axios
    .get('/api/v1/vehicles/used/colors', config)
    .then((response) => response.data);
};

/**
 * @param {number} vehicleId
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getVehicle = (vehicleId, config = undefined) => {
  return axios
    .get(route('api.v1.vehicles.show', [vehicleId]), config)
    .then((response) => response.data.data);
};

/**
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getVehicles = (request, config = undefined) => {
  return axios
    .get('/api/v1/vehicles', { ...config, params: request })
    .then((response) => response.data);
};
