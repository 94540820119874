import axios from '../../plugins/axios.js';

/**
 * @param {object} request filters
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getNotifications = (request, config = undefined) => {
  return axios
    .get('/api/v1/notifications', { ...config, params: request })
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {number[]|number} notificationId
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const markNotificationAsSeen = (notificationId, config = undefined) => {
  return axios
    .post(
      `/api/v1/notifications/bulk/mark-as-seen`,
      { notifications: notificationId },
      config
    )
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {number} templateId
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const bulkUnsubscribe = (templateId, config = undefined) => {
  return axios
    .post(`/api/v1/notifications/bulk/unsubscribe`, { id: templateId }, config)
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {number} templateId
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const bulkSubscribe = (templateId, config = undefined) => {
  return axios
    .post(`/api/v1/notifications/bulk/subscribe`, { id: templateId }, config)
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getUnseenCounter = (config = undefined) => {
  return axios
    .get('/api/v1/notifications/unseen-count', config)
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getUserSubscriptions = (config = undefined) => {
  return axios
    .get('/api/v1/notifications/bulk/subscriptions', config)
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getBulkLists = (config = undefined) => {
  return axios
    .get('/api/v1/notifications/bulk/lists', config)
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {string} section
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getTemplatesForSection = (section, config = undefined) => {
  return axios
    .get(`/api/v1/notifications/templates/${section}`, config)
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {string} systemId
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getSubscriptionsForSection = (systemId, config = undefined) => {
  return axios
    .get(`/api/v1/notifications/subscriptions/${systemId}`, config)
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {object} request
 * @param {number} request.notification_template_id
 * @param {number} request.system_id
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const subscribe = (request, config = undefined) => {
  return axios
    .post(`/api/v1/notifications/subscribe`, request, config)
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {object} request
 * @param {number} request.notification_template_id
 * @param {number} request.system_id
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const unsubscribe = (request, config = undefined) => {
  return axios
    .post(`/api/v1/notifications/unsubscribe`, request, config)
    .then((response) => {
      return response.data;
    });
};
