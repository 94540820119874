import { computed, reactive, ref, watch } from 'vue';
import useDebounce from '@/composables/request/debounce.js';

/**
 * @callback searchRequest
 * @param {AbortController} abortSignal
 * @param {string} value
 * @param {object} [extraFields]
 */

/**
 * @typedef DebounceConfig
 * @property {object} config
 * @property {number} [config.debounceTime]
 * @property {string} [config.noDataText]
 * @property {number} [config.minSearchLength]
 * @property {object} [config.extraFields]
 */

/**
 * @param {searchRequest} searchRequest
 * @param {DebounceConfig} [config]
 * @returns {object}
 */
export function useDebounceRequest(searchRequest, config = {}) {
  let modelValue = ref(config.id || null);
  let items = reactive([]);
  let minSearchLength = config.minSearchLength || 3;
  const itemSearch = ref('');
  const loading = ref(false);

  if (config.id) {
    config.extraFields = config.extraFields || {};
    config.extraFields.id = config.id;
    console.warn('`id` property is deprecated. Use `extraFields` instead.');
  }

  /**
   * @param {string} value
   */
  const requestData = (value) => {
    items.splice(0);

    searchRequest(requestAbortSignal, value, config.extraFields);
  };

  let { debounceRequest, abortSignal: requestAbortSignal } = useDebounce({
    request: (abortSignal) => {
      items.splice(0);
      searchRequest(abortSignal, itemSearch.value, config.extraFields);
    },
  });

  watch(itemSearch, (value, oldValue) => {
    if (
      value === oldValue ||
      value?.length < minSearchLength ||
      value?.length - oldValue?.length > 1
    ) {
      if (!modelValue.value) {
        items.splice(0);
      }

      return;
    }

    loading.value = true;
    debounceRequest(value);
  });

  const noDataText = computed(() => {
    if (loading.value) return 'Loading...';

    return itemSearch.value?.length > minSearchLength - 1
      ? config.noDataText || 'No items found'
      : `Write at least ${minSearchLength} characters to search`;
  });

  /**
   * @param {(number[]|number)} value
   */
  const setPreloadValue = (value) => {
    modelValue.value = value;
    config.id = value;
    requestData(itemSearch.value);
  };

  if (config.id) {
    setPreloadValue(config.id);
  }

  return {
    attrs: reactive({
      modelValue,
      search: itemSearch,
      items,
      loading,
      noDataText,
      noFilter: true,
    }),

    listeners: {
      'update:modelValue': (value) => (modelValue.value = value),
      'update:search': (value) => (itemSearch.value = value),
    },

    modelValue,
    itemSearch,
    items,
    loading,
    noDataText,
    requestAbortSignal,
    setPreloadValue,
  };
}
