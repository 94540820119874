<template>
  <v-responsive>
    <v-app :theme="theme" class="bg-red-gradient">
      <v-main>
        <v-container>
          <slot />

          <v-btn
            style="position: fixed; bottom: 1rem; right: 1rem"
            :prepend-icon="icon"
            @click="onClick"
          />
        </v-container>
      </v-main>
    </v-app>
  </v-responsive>
</template>

<script setup>
import { ref, computed } from 'vue';

const theme = ref('light');

const icon = computed(() => {
  return theme.value === 'light' ? 'mdi-weather-sunny' : 'mdi-weather-night';
});

function onClick() {
  theme.value = theme.value === 'light' ? 'dark' : 'light';
}
</script>
