import axios from '../../plugins/axios.js';

export const getRoles = (request, config = undefined) => {
  return axios
    .get('/api/v1/roles', { ...config, params: request })
    .then((response) => {
      return response.data;
    });
};

export const getRole = (id, config = undefined) => {
  return axios.get(`/api/v1/roles/${id}`, config).then(({ data }) => {
    let debt = data.data;
    debt.reason = parseInt(debt.reason);

    return debt;
  });
};

export const getUsers = (request, config = undefined) => {
  return axios
    .get('/api/v1/users', { ...config, params: request })
    .then((response) => {
      return response.data;
    });
};
export const createUser = (user, config = undefined) => {
  return axios.post('/api/v1/users', user, config).then(({ data }) => {
    return data;
  });
};

export const changePassword = (user_id, user, config = undefined) => {
  return axios
    .post(`/api/v1/users/${user_id}/change-password`, user, config)
    .then(() => {
      return true;
    });
};

export const getUser = (id, config = undefined) => {
  return axios.get(`/api/v1/users/${id}`, config).then(({ data }) => {
    let debt = data.data;
    debt.reason = parseInt(debt.reason);

    return debt;
  });
};

export const getPermissions = (id, config = undefined) => {
  return axios
    .get(`/api/v1/users/${id}/permissions`, config)
    .then(({ data }) => {
      let permissions = data.data;

      return permissions;
    });
};

/**
 * @param {number} id
 * @param {object} role
 * @param {number} role.role_id
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const addRoleToUser = (id, role, config = undefined) => {
  return axios
    .post(`/api/v1/users/${id}/roles`, role, config)
    .then(({ data }) => data);
};

/**
 * @param {number} id
 * @param {object} role
 * @param {number} role.role_id
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const removeRoleToUser = (id, role, config = undefined) => {
  config = {
    data: role,
    ...config,
  };

  return axios
    .delete(`/api/v1/users/${id}/roles`, config)
    .then(({ data }) => data);
};

/**
 * @param {number} id
 * @param {object} department
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const addDepartmentToUser = (id, department, config = undefined) => {
  return axios
    .post(`/api/v1/users/${id}/departments`, department, config)
    .then(({ data }) => data);
};

/**
 * @param {number} id
 * @param {object} department
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const removeDepartmentToUser = (id, department, config = undefined) => {
  config = {
    data: department,
    ...config,
  };

  return axios
    .delete(`/api/v1/users/${id}/departments`, config)
    .then(({ data }) => data);
};

/**
 * @param {number} id
 * @param {object} debt
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const addDebtToUser = (id, debt, config = undefined) => {
  return axios
    .post(`/api/v1/users/${id}/debts`, debt, config)
    .then(({ data }) => data);
};

/**
 * @param {number} id
 * @param {object} debt
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const removeDebtToUser = (id, debt, config = undefined) => {
  config = {
    data: debt,
    ...config,
  };

  return axios
    .delete(`/api/v1/users/${id}/debts`, config)
    .then(({ data }) => data);
};

export const getEmployees = (request, config = undefined) => {
  return axios
    .get('/api/v1/users/employees', { ...config, params: request })
    .then((response) => response.data.data);
};

/**
 * @param {object} request
 * @param {string} request.email
 * @param {string} request.password
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const login = (request, config = undefined) => {
  return axios.post('/login', request, config).then((response) => {
    return response.data;
  });
};
