import { reactive, ref } from 'vue';

const itemsPerPageOptions = [
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' },
];

/**
 * @param {object} config
 * @param {Array} config.headers
 * @param {Function} config.fetchRequest
 * @param {Function} [config.prepareSearch]
 * @returns {object}
 */
export default function useTable(config = {}) {
  if (!config.fetchRequest) {
    throw new Error('fetchRequest is required');
  }

  if (!config.headers) {
    throw new Error('headers is required');
  }

  const loading = ref(false);
  const items = ref([]);
  const itemsPerPage = ref(25);
  const itemsLength = ref(0);
  const page = ref(1);
  const sortBy = ref(config.sortBy || []);
  const headers = config.headers;

  function fetchItems() {
    if (loading.value) return;

    loading.value = true;
    if (config.clearItems) {
      items.value = [];
    }

    const search = config.prepareSearch ? config.prepareSearch() : {};
    search.page = page.value || 1;
    search.per_page = itemsPerPage.value;
    search.sortBy = sortBy.value;

    config
      .fetchRequest(search)
      .then((pagination) => {
        if (pagination.meta) {
          page.value = pagination.meta.current_page;
          itemsLength.value = pagination.meta.total;
        } else {
          itemsLength.value = pagination.total;
          page.value = pagination.current_page;
        }

        items.value = pagination.data;
      })
      .finally(() => {
        loading.value = false;
      });
  }

  fetchItems();

  return {
    fetchItems,

    attrs: reactive({
      loading,
      itemsLength,
      itemsPerPage,
      items,
      itemsPerPageOptions,
      headers,
      page,
      sortBy,
    }),

    listeners: {
      'update:itemsPerPage': (value) => (itemsPerPage.value = value),
      'update:page': (value) => (page.value = value),
      'update:sortBy': (value) => (sortBy.value = value),
      'update:options': fetchItems,
    },
  };
}
