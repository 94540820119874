<template>
  <v-row>
    <v-col cols="12">
      <v-autocomplete
        v-model="model.tow_by"
        :items="towingCompanies"
        :loading="loadingTowingCompanies"
        :error-messages="errors?.tow_by"
        label="Tow by"
      />
    </v-col>
  </v-row>
</template>

<script setup>
import { useTowingCompanyOptions } from '@/composables/form/optionsForm.js';
import { stepProps } from '@/composables/task/stepOptions.js';

defineProps({
  ...stepProps,
});

const model = defineModel({ required: true, type: Object });
model.value.tow_by = 831;

const { towingCompanies, loadingTowingCompanies, loadTowingCompanies } =
  useTowingCompanyOptions();

loadTowingCompanies();
</script>
