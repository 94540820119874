<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col cols="12">
          <v-text-field v-model="rol.rol_name" label="Role name" />
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="rol.rol_description"
            label="Description"
            maxlength="300"
            rows="5"
          />
        </v-col>

        <v-col cols="12">
          <h3 class="text-center">Permissions</h3>
          <br />

          <template
            v-for="(groupPermissions, index) in permissions"
            :key="index"
          >
            <div
              :class="{
                h3: !index.includes('.'),
                'h5 px-3': index.includes('.'),
              }"
            >
              {{ buildHeader(index) }}
              <input type="checkbox" />
            </div>
            <v-row :class="{ 'px-4': index.includes('.') }">
              <template
                v-for="permission in groupPermissions"
                :key="permission"
              >
                <v-col cols="6">
                  <v-checkbox v-model="rol[permission.permission]">
                    <template #label>
                      <div class="text-body-1">
                        {{ buildLabel(permission) }}
                        <v-chip
                          v-if="!legacyPermissions[permission.permission]"
                          color="red"
                        >
                          Not implemented
                        </v-chip>
                      </div>
                    </template>
                  </v-checkbox>
                </v-col>
              </template>
            </v-row>
          </template>

          <v-btn color="green" block> Opslaan </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import legacyPermissions from '@/data/permissions.json';
import {
  capitalizeFirstLetter,
  normalizeText,
} from '@/utils/formatter/text.js';

export default {
  data: () => ({
    rol: {},
    legacyPermissions,
    texts: {
      accounting: { title: 'Accounting', text: '' },
      admin: { title: 'Administration (System)', text: '' },
      debt: { title: 'Debt (Incasso/Regulier)', text: '' },
      reprimand: { title: 'Certified letter', text: '' },
      invoice: { title: 'Invoice', text: '' },
      repossession: { title: 'Repossession', text: '' },
      storage: { title: 'Storage', text: '' },
      impound: { title: 'Impound', text: '' },
      patrol: { title: 'Patrol', text: '' },
      meeting: { title: 'Meeting', text: '' },
      note: { title: 'Note', text: '' },
      user: { title: 'User', text: '' },
    },
    printed: [],
    permissions: {},
  }),
  created() {
    /** @type {string[]} permissions */
    let permissions = JSON.parse(
      document.getElementById('shared-data').dataset.permissions
    );

    permissions = permissions.map((permission) => {
      let group = permission.match(/(^\w*?)\./)[1];
      let type = permission.match(/\.(\w*?)$/)[1];
      let subGroup = permission.match(/\.(\w*?)\./);
      subGroup = subGroup && subGroup[1];

      return {
        permission,
        group,
        type,
        subGroup,
      };
    });

    this.permissions = Object.groupBy(permissions, (permission) =>
      permission.subGroup
        ? permission.group + '.' + permission.subGroup
        : permission.group
    );
  },
  methods: {
    buildLabel(permission) {
      return normalizeText(
        capitalizeFirstLetter(permission.type) +
          ' ' +
          this.buildHeader(permission.subGroup || permission.group)
      );
    },
    buildHeader(index) {
      let header =
        (this.texts[index] && this.texts[index]['title']) ||
        capitalizeFirstLetter(index);

      return normalizeText(header);
    },
    printOnce(permission) {
      let key = permission.group + permission.subGroup;

      return this.printed.indexOf(key) === -1 ? this.printed.push(key) : false;
    },
  },
};
</script>
