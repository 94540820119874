<template>
  <v-card flat>
    <v-table>
      <v-overlay
        v-model="overlay"
        contained
        class="align-center justify-center"
        scroll-strategy="block"
      >
        <v-progress-circular color="primary" indeterminate size="100" />
      </v-overlay>
      <thead>
        <tr>
          <th>Beperking vertragingsrente (%)</th>
          <th>Begindatum</th>
          <th>Opties</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="penalty in penalties" :key="penalty.penalty_id">
          <td>{{ penalty.penalty_percentage }}%</td>
          <td>{{ formatDate(penalty.first_date) }}</td>
          <td class="text-right">
            <div class="btn-group btn-group-sm" role="group">
              <DeleteButton
                :url="`/api/v1/debts/${penalty.debt_id}/penalties/${penalty.penalty_id}`"
                @deleted="fetchPenalties"
              />
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr style="vertical-align: top">
          <th class="p-2">
            <v-text-field
              v-model="form.penalty_percentage"
              type="number"
              step="0.01"
              label="Percentage"
              :error-messages="errors.penalty_percentage"
            />
          </th>
          <th class="p-2">
            <v-text-field
              v-model="form.first_date"
              type="date"
              label="Begindatum"
              :error-messages="errors.first_date"
            />
          </th>
          <th class="p-2">
            <v-btn color="green" block @click="storePenalty">
              <i class="fas fa-plus"></i>
            </v-btn>
          </th>
        </tr>
      </tfoot>
    </v-table>
  </v-card>
</template>

<script>
import DeleteButton from '@/components/general/DeleteButton.vue';
let axios = window.axios;

export default {
  components: {
    DeleteButton,
  },
  props: {
    debtId: {
      type: [Number, String],
      required: true,
    },
  },
  data: () => ({
    penalties: [],
    errors: {},
    loading: false,
    saving: false,
    form: {
      penalty_percentage: null,
      first_date: null,
    },
  }),
  computed: {
    overlay() {
      return this.loading || this.saving;
    },
  },
  created() {
    this.fetchPenalties();
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('nl-NL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
    },
    fetchPenalties() {
      this.loading = true;

      axios
        .get(`/api/v1/debts/${this.debtId}/penalties`)
        .then(({ data }) => {
          this.penalties = data.data;
        })
        .finally(() => (this.loading = false));
    },
    storePenalty() {
      this.saving = true;
      this.errors = {};
      axios
        .post(`/api/v1/debts/${this.debtId}/penalties`, this.form)
        .then(() => {
          this.form = {
            penalty_percentage: null,
            first_date: null,
          };
          this.fetchPenalties();
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors;
          }
        })
        .finally(() => (this.saving = false));
    },
  },
};
</script>
