import axios from '../../plugins/axios.js';

/**
 * @param {object} request
 * @param {string} request.service_name
 * @param {boolean} request.repo
 * @param {boolean} request.reprimand
 * @param {boolean} request.debt
 * @param {boolean} request.storage
 * @param {boolean} request.note
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
const getServices = (request, config = undefined) => {
  return axios
    .get('/api/v1/services', { ...config, params: request })
    .then(({ data }) => data.data);
};

/**
 * @param {number} id
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
const getService = (id, config = undefined) => {
  return axios
    .get(`/api/v1/services/${id}`, config)
    .then(({ data }) => data.data);
};

export { getService, getServices };
