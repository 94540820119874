<template>
  <v-row>
    <v-col cols="12" class="justify-content-between">
      <v-btn
        v-for="(preset, key) in presets"
        :key="key"
        color="grey"
        class="mr-1"
        :text="'Preset ' + key"
        @click="setPreset(key)"
      />
    </v-col>

    <!-- Provisie -->
    <v-col cols="12" md="6">
      <v-checkbox
        v-model="form.rsi_part_time_scale"
        label="RSI provisie met tijdschaal"
      />
    </v-col>

    <v-col v-if="!form.rsi_part_time_scale" cols="12">
      <v-row>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_part"
            label="RSI provisie"
            type="number"
            suffix="%"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_part_fixed"
            label="RSI vaste provisie"
            type="number"
            suffix="%"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_part_sum"
            label="RSI vaste provisie"
            type="number"
            suffix="Afl."
          />
        </v-col>

        <template v-if="calculations">
          <v-col />
          <v-col cols="4" md="2" class="text-right">
            <span class="font-weight-bold">RSI provisie</span>
            <br />
            {{ rsiPartPercentage }}%
          </v-col>
        </template>
      </v-row>
    </v-col>

    <!-- Incasso -->
    <v-col cols="12">
      <v-row>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.recovery"
            :label="'Beperking ' + recoveryType"
            type="number"
            suffix="%"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.recovery_fixed"
            :label="'Vaste ' + recoveryType"
            type="number"
            suffix="%"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.recovery_sum"
            :label="'Vaste ' + recoveryType"
            type="number"
            suffix="Afl."
          />
        </v-col>

        <template v-if="calculations">
          <v-col />
          <v-col cols="4" md="2" class="text-right">
            <span class="font-weight-bold text-capitalize">{{
              recoveryType
            }}</span>
            <br />
            {{ rsiPartPercentage }}%
          </v-col>
        </template>
      </v-row>
    </v-col>

    <!-- Bank provisie -->
    <v-col cols="12">
      <v-row>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.bank_cost"
            label="Bankkosten"
            type="number"
            suffix="%"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.bank_cost_sum"
            label="Bankkosten"
            type="number"
            suffix="Afl."
          />
        </v-col>
      </v-row>
    </v-col>

    <!-- Rent -->
    <v-col cols="12">
      <v-radio-group
        v-model.number="form.interest_distribution_mode"
        label="Verdelen van de rente en boete"
        inline
      >
        <v-row>
          <v-col cols="12" lg="3">
            <v-radio label="Eindtotaalschaal" :value="0" />
          </v-col>
          <v-col cols="12" lg="3">
            <v-radio label="Tijdschaal" :value="2" />
          </v-col>
          <v-col cols="12" lg="3">
            <v-radio label="Vast" :value="1" />
          </v-col>
        </v-row>
      </v-radio-group>
    </v-col>

    <v-col v-if="form.interest_distribution_mode == 1" cols="12">
      <!-- Rent before -->
      <v-row>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_interest_before"
            label="Vaste rente voor interventie"
            type="number"
            suffix="%"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_interest_before_sum"
            label="Vaste rente voor interventie"
            type="number"
            suffix="Afl."
          />
        </v-col>

        <template v-if="calculations">
          <v-col />
          <v-col cols="4" md="2" class="text-right">
            <span class="font-weight-bold">Rente voor</span>
            <br />
            {{ interestBeforePercentage }}%
          </v-col>
        </template>
      </v-row>

      <!-- Rent after -->
      <v-row>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_interest_after"
            label="Vaste rente na interventie"
            type="number"
            suffix="%"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_interest_after_sum"
            label="Vaste rente na interventie"
            type="number"
            suffix="Afl."
          />
        </v-col>

        <template v-if="calculations">
          <v-col />
          <v-col cols="4" md="2" class="text-right">
            <span class="font-weight-bold">Rente na</span>
            <br />
            {{ interestAfterPercentage }}%
          </v-col>
        </template>
      </v-row>

      <!-- Rent after -->
      <v-row>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_penalty"
            label="Vaste boete"
            type="number"
            suffix="%"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model.number="form.rsi_penalty_sum"
            label="Vaste boete"
            type="number"
            suffix="Afl."
          />
        </v-col>

        <template v-if="calculations">
          <v-col />
          <v-col cols="4" md="2" class="text-right">
            <span class="font-weight-bold">Boete</span>
            <br />
            {{ penaltyPercentage }}%
          </v-col>
        </template>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
let axios = window.axios;

export default {
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    calculations: {
      type: Boolean,
      default: false,
    },
    debt: {
      type: Object,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  data: () => ({
    formData: {
      rsi_part_time_scale: true,
      rsi_part: null,
      rsi_part_fixed: null,
      rsi_part_sum: null,
      recovery: null,
      recovery_fixed: null,
      recovery_sum: null,
      interest_distribution_mode: null,
      rsi_interest_before: null,
      rsi_interest_before_sum: null,
      rsi_interest_after: null,
      rsi_interest_after_sum: null,
      rsi_penalty: null,
      rsi_penalty_sum: null,
    },
    presets: [],
  }),
  computed: {
    form: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      },
    },
    penaltyPercentage() {
      let percentage =
        parseFloat(
          this.debt?.calculations.total_penalty > 0 &&
            (this.debt?.calculations.penalty_commission /
              this.debt?.calculations.total_penalty) *
              100
        ) || 0;

      return percentage.toFixed(2);
    },
    interestBeforePercentage() {
      let percentage =
        parseFloat(
          this.debt?.calculations.total_overview_interest > 0 &&
            (this.debt?.calculations.interest_before_commission /
              this.debt?.calculations.total_overview_interest) *
              100
        ) || 0;

      return percentage.toFixed(2);
    },
    interestAfterPercentage() {
      let percentage =
        parseFloat(
          this.debt?.calculations.total_overview_interest > 0 &&
            (this.debt?.calculations.interest_after_commission /
              this.debt?.calculations.total_overview_interest) *
              100
        ) || 0;

      return percentage.toFixed(2);
    },
    rsiPartPercentage() {
      let percentage =
        parseFloat(
          this.debt?.calculations.principal_debt > 0 &&
            (this.debt?.calculations.rsi_part_total /
              this.debt?.calculations.principal_debt) *
              100
        ) || 0;

      return percentage.toFixed(2);
    },
    recoveryPercentage() {
      let percentage =
        parseFloat(
          this.debt?.calculations.principal_debt > 0 &&
            (this.debt?.calculations.total_recovery /
              this.debt?.calculations.principal_debt) *
              100
        ) || 0;

      return percentage.toFixed(2);
    },
    recoveryType() {
      return this.debt?.rental ? 'makelaarsprovisie' : 'incassokosten';
    },
  },
  watch: {
    debt(value) {
      this.updateForm(value);
    },
  },
  created() {
    this.fetchPreset();
    this.updateForm(this.debt.preset);
  },
  methods: {
    setPreset(key) {
      this.updateForm(this.presets[key]);
    },
    updateForm(preset) {
      this.form = {
        rsi_part_time_scale: Boolean(preset.rsi_part_time_scale),
        rsi_part: parseFloat(preset.rsi_part),
        rsi_part_fixed: parseFloat(preset.rsi_part_fixed),
        rsi_part_sum: parseFloat(preset.rsi_part_sum),
        recovery: parseFloat(preset.recovery),
        recovery_fixed: parseFloat(preset.recovery_fixed),
        recovery_sum: parseFloat(preset.recovery_sum),
        bank_cost: parseFloat(preset.bank_cost),
        bank_cost_sum: parseFloat(preset.bank_cost_sum),
        interest_distribution_mode: parseInt(preset.interest_distribution_mode),
        rsi_interest_before: parseFloat(preset.rsi_interest_before),
        rsi_interest_before_sum: parseFloat(preset.rsi_interest_before_sum),
        rsi_interest_after: parseFloat(preset.rsi_interest_after),
        rsi_interest_after_sum: parseFloat(preset.rsi_interest_after_sum),
        rsi_penalty: parseFloat(preset.rsi_penalty),
        rsi_penalty_sum: parseFloat(preset.rsi_penalty_sum),
      };
    },
    fetchPreset() {
      axios.get('/api/v1/debts/presets').then(({ data }) => {
        this.presets = data;
      });
    },
  },
};
</script>
