import { departmentLink, personLink } from '../../templates/general/links';

const calculateTotalPaymentPercentage = (debt) => {
  let total_payment = parseFloat(debt.total_payment || 0);
  let finaltotal = parseFloat(debt.finaltotal || 0);

  if (parseFloat(debt.contract)) {
    finaltotal = parseFloat(debt.contract || 0) + total_payment;
  }

  return ((total_payment / parseFloat(finaltotal)) * 100).toFixed(2);
};

const debtorsInfo = (debtors) => {
  let cell = '';

  debtors?.persons?.forEach((person) => {
    cell += `
    <div class="d-block w-100">
      ${personLink(person)}
    </div>
    `;
  });

  debtors?.companies?.forEach((company) => {
    cell += `
    <div class="d-block w-100">
      ${departmentLink(company)}
    </div>
    `;
  });

  if (cell === '') {
    Array.from(debtors).forEach((debtor) => {
      if (!debtor.person && !debtor.department) {
        return;
      }

      cell += `
      <div class="d-block w-100">
        ${
          debtor.person
            ? personLink(debtor.person)
            : departmentLink(debtor.department)
        }
      </div>
      `;
    });
  }

  return cell;
};

const creditorsInfo = (creditors) => {
  let cell = '';

  creditors?.persons?.forEach((person) => {
    cell += `
    <div class="d-block w-100">
      ${personLink(person)}
    </div>
    `;
  });

  creditors?.companies?.forEach((company) => {
    cell += `
    <div class="d-block w-100">
      ${departmentLink(company)}
    </div>
    `;
  });

  if (cell == '') {
    Array.from(creditors).forEach((creditor) => {
      if (!creditor.person && !creditor.department) {
        return;
      }

      cell += `
      <div class="d-block w-100">
        ${
          creditor.person
            ? personLink(creditor.person)
            : departmentLink(creditor.department)
        }
      </div>
      `;
    });
  }

  return cell;
};

export { calculateTotalPaymentPercentage, debtorsInfo, creditorsInfo };
