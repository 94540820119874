import { useDebounceRequest } from './debounce-request.js';
import { getVehicle } from '../../services/api/search.js';

/**
 * @param {import('./debounce-request.js').DebounceConfig} [config]
 * @returns {import('./debounce-request.js').DebounceResponse}
 */
export function useVehicleAutocomplete(config = {}) {
  /** @type {import('./debounce-request.js').searchRequest} */
  const searchRequest = (abortSignal, search, id) => {
    const axiosConfig = {
      signal: abortSignal.signal,
    };

    getVehicle({ search, id }, axiosConfig)
      .then((v) => {
        vehicles.push(
          ...v.map((vehicle) => {
            let titleArray = [
              vehicle.make,
              vehicle.model,
              vehicle.year,
              vehicle.color,
              vehicle.chassis_number,
              vehicle.engine_number,
            ].filter((title) => title !== null);

            return {
              title: titleArray.join('; '),
              value: parseInt(vehicle.id),
            };
          })
        );
      })
      .finally(() => (loadingVehicles.value = false));
  };

  const {
    attrs,
    listeners,
    modelValue: vehicleModel,
    itemSearch: vehicleSearch,
    items: vehicles,
    loading: loadingVehicles,
    noDataText: noVehicleDataText,
    setPreloadValue: setPreselectedVehicle,
  } = useDebounceRequest(searchRequest, config);

  return {
    attrs,
    listeners,

    vehicleModel,
    vehicles,
    vehicleSearch,
    loadingVehicles,
    noVehicleDataText,
    setPreselectedVehicle,
  };
}
