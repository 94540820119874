import {
  getVehicle,
  getVehicleColors,
  getVehicleFuelTypes,
  getVehicleGearboxes,
  getVehicleMakes,
  getVehicleTypes as getVehicleTypesApi,
} from '@/services/api/vehicle.js';
import { reactive, ref } from 'vue';

let gearboxes = ref([]);
let vehicleTypes = ref([]);
let fuelTypes = ref([]);
let colors = ref([]);
let makes = ref([]);

export const vehicle = reactive({});

export const fetchStorage = (vehicleId) => {
  return getVehicle(vehicleId).then((_vehicle) => {
    Object.assign(vehicle, _vehicle);

    return _vehicle;
  });
};

export const getGearboxes = () => {
  if (!gearboxes.value.length) {
    getVehicleGearboxes().then((_gearboxes) => {
      gearboxes.value = _gearboxes;
    });
  }

  return gearboxes;
};

export const getVehicleTypes = () => {
  if (!vehicleTypes.value.length) {
    getVehicleTypesApi().then((_vehicleTypes) => {
      vehicleTypes.value = _vehicleTypes;
    });
  }

  return vehicleTypes;
};

export const getFuelTypes = () => {
  if (!fuelTypes.value.length) {
    getVehicleFuelTypes().then((_fuelTypes) => {
      fuelTypes.value = _fuelTypes;
    });
  }

  return fuelTypes;
};

export const getColors = () => {
  if (!colors.value.length) {
    getVehicleColors().then((_colors) => {
      colors.value = _colors;
    });
  }

  return colors;
};

export const getMakes = () => {
  if (!makes.value.length) {
    getVehicleMakes().then((_makes) => {
      makes.value = _makes;
    });
  }

  return makes;
};
