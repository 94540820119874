<template>
  <VProgressCircular v-if="loading" indeterminate />

  <div v-else-if="!links.length">
    Geen {{ resType == 'creditor' ? 'Crediteur' : 'Debiteur' }}
  </div>

  <div
    v-for="(link, index) in links"
    v-else
    :key="linkKey(link)"
    class="d-flex justify-content-between py-1"
  >
    <template v-if="link.department">
      <DepartmentLink :department="link.department" bold dep />
    </template>

    <template v-else>
      <PeopleLink :person="link.person" bold middle-name />
    </template>

    <div class="d-print-none">
      <template v-if="links.length > 1 && canMove">
        <VBtn
          v-if="links.length > 1 && index"
          v-bind="btnStyle"
          icon="mdi-arrow-up"
          color="blue"
          @click="moveItem(link, 'up')"
        />
        <VBtn
          v-if="links.length > 1 && index < links.length - 1"
          v-bind="btnStyle"
          icon="mdi-arrow-down"
          color="blue"
          @click="moveItem(link, 'down')"
        />
      </template>

      <DeleteButton
        v-if="deleteBtn"
        :url="removeUrl(link)"
        @deleted="linkDeleted(index)"
      >
        <template #default="{ props }">
          <VBtn
            v-can="linkType + '.delete'"
            v-bind="{ ...btnStyle, ...props }"
            icon="mdi-trash-can"
            color="red"
          />
        </template>
      </DeleteButton>
    </div>
  </div>
</template>

<script setup>
import {
  getLinks as getDebtLinks,
  sortLinks as sortDebtLinks,
} from '@/services/api/debt.js';
import {
  getLinks as getRepossessionLinks,
  sortLinks as sortRepossessionLinks,
} from '@/services/api/repossession.js';
import {
  getLinks as getReprimandLinks,
  sortLinks as sortReprimandLinks,
} from '@/services/api/reprimand.js';
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import DeleteButton from '@/components/general/DeleteButton.vue';
import { ref } from 'vue';

const { resType, linkType, linkId } = defineProps({
  linkId: {
    type: [Number, String],
    required: true,
    default: null,
  },
  resType: {
    type: String,
    required: true,
    default: null,
  },
  linkType: {
    type: String,
    required: true,
    default: null,
  },
  deleteBtn: {
    type: Boolean,
    default: false,
  },
  canMove: {
    type: Boolean,
    default: false,
  },
});

const links = ref([]);
const loading = ref(true);
const btnStyle = ref({
  elevation: 0,
  density: 'compact',
  variant: 'text',
  size: 'small',
});

fetchLinks();
/**
 * @param {object} link
 * @returns string
 */
function removeUrl(link) {
  if (linkType == 'debt') {
    return `/api/v1/debts/links/${link.id}`;
  } else if (linkType == 'repossession') {
    return `/api/v1/repossessions/links/${link.id}`;
  } else if (linkType == 'reprimand') {
    return `/api/v1/reprimands/links/${link.id}`;
  }

  return '';
}
/**
 * @param {object} link
 * @returns {number|null}
 */
function linkKey(link) {
  if (linkType == 'debt') {
    return link.id;
  } else if (linkType == 'repossession') {
    return link.id;
  } else if (linkType == 'reprimand') {
    return link.id;
  }

  return null;
}
function fetchLinks() {
  if (!Number.isInteger(parseInt(linkId))) {
    console.warn(`Link id is not a number (${linkId})`);
    return;
  }

  loading.value = true;

  if (linkType == 'debt') {
    getDebtLinks(linkId, resType).then((_links) => {
      links.value = _links;
      loading.value = false;
    });
  } else if (linkType == 'repossession') {
    getRepossessionLinks(linkId, resType).then((_links) => {
      links.value = _links;
      loading.value = false;
    });
  } else if (linkType == 'reprimand') {
    getReprimandLinks(linkId, resType).then((_links) => {
      links.value = _links;
      loading.value = false;
    });
  }
}

/**
 * @param {object} link
 * @param {('down'|'up')} direction
 */
function moveItem(link, direction) {
  loading.value = true;

  console.log(link);

  if (linkType == 'debt') {
    sortDebtLinks(link.id, direction).then(() => {
      fetchLinks();
    });
  } else if (linkType == 'repossession') {
    sortRepossessionLinks(link.id, direction).then(() => {
      fetchLinks();
    });
  } else if (linkType == 'reprimand') {
    sortReprimandLinks(link.id, direction).then(() => {
      fetchLinks();
    });
  }
}

/**
 *
 * @param {number} index
 */
function linkDeleted(index) {
  links.value.splice(index, 1);
}
</script>
