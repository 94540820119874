<template>
  <div>
    <v-tabs v-model="tab" bg-color="primary" class>
      <v-tab value="roles">Roles ({{ access.roles.length }})</v-tab>
      <v-tab value="departments">
        Departments ({{
          canManageAllDepartments ? 'All' : access.departments.length
        }})
      </v-tab>
      <v-tab value="debts">
        Debts ({{ canManageAllDepartments ? 'All' : access.debts.length }})
      </v-tab>
    </v-tabs>
  </div>

  <v-card-text>
    <v-form>
      <v-window v-model="tab" class="p-2">
        <v-window-item value="roles">
          <v-row>
            <v-col cols="10">
              <v-autocomplete
                v-model="roleModel"
                v-model:search="roleSearch"
                :items="roles"
                :loading="loadingRoles"
                :no-data-text="noRoleDataText"
                label="Role to add"
                no-filter
                clearable
              />
            </v-col>
            <v-col cols="2">
              <v-btn
                color="green"
                block
                :disabled="!roleModel"
                @click="addRole"
              >
                Add role
              </v-btn>
            </v-col>

            <v-col cols="12">
              <div class="font-weight-bold">
                Roles ({{ access.roles.length }}):
              </div>
              <v-chip
                v-for="role in access.roles"
                :key="role"
                class="ma-1"
                color="green"
                variant="flat"
                density="comfortable"
                closable
                @click:close="removeRole(role)"
              >
                {{ role.name }}
              </v-chip>
            </v-col>

            <v-col cols="12">
              <div class="font-weight-bold">
                Permissions ({{ access.permissions.length }}):
              </div>
              <v-chip
                v-for="permission in access.permissions"
                :key="permission"
                class="ma-1"
                color="blue"
                variant="tonal"
              >
                {{ permission }}
              </v-chip>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item value="departments">
          <v-row v-if="canManageAllDepartments" cols="12">
            <div class="text-center text-warning">
              This user have access to all departments.
            </div>
          </v-row>

          <v-row>
            <v-col cols="10">
              <v-autocomplete
                v-model="departmentModel"
                v-model:search="departmentSearch"
                :items="departments"
                :loading="loadingDepartments"
                :no-data-text="noDepartmentDataText"
                label="Department to add"
                no-filter
                clearable
              />

              <small>
                Write the name of the company and to filter the department add a
                @ to specified the department. (e.g. company@department)
              </small>
            </v-col>

            <v-col cols="2">
              <v-btn
                v-tooltip="'Add department'"
                variant="flat"
                color="green"
                block
                :disabled="!departmentModel"
                @click="addDepartment"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>

            <v-col cols="12">
              <div class="font-weight-bold">
                Departments ({{ access.departments.length }}):
              </div>
              <v-chip
                v-for="department in access.departments"
                :key="department"
                class="ma-1"
                color="green"
                variant="flat"
                density="comfortable"
                closable
                @click:close="removeDepartment(department)"
              >
                <DepartmentLink :department="department" dep />
              </v-chip>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item value="debts">
          <v-row v-if="canManageAllDepartments" cols="12">
            <div>This user have access to all debts</div>
          </v-row>

          <v-row v-else>
            <v-col cols="10">
              <v-autocomplete
                v-model="debtModel"
                v-model:search="debtSearch"
                :items="debts"
                :loading="loadingDebts"
                :no-data-text="noDebtDataText"
                label="Debt to add"
                no-filter
                clearable
              />
            </v-col>

            <v-col cols="2">
              <v-btn
                v-tooltip="'Add debt'"
                variant="flat"
                color="green"
                block
                icon="mdi-plus"
                :disabled="!debtModel"
                @click="addDebt"
              />
            </v-col>

            <v-col cols="12">
              <div>Debts ({{ access.debts.length }}):</div>

              <v-tooltip v-for="debt in access.debts" :key="debt.ref_num">
                <template #activator="{ props }">
                  <v-chip
                    class="ma-1"
                    color="green"
                    density="comfortable"
                    variant="flat"
                    closable
                    v-bind="props"
                    @click:close="removeDebt(debt)"
                  >
                    {{ debt.ref_num }}
                  </v-chip>
                </template>
                <div v-for="link in debt.links" :key="link.id">
                  <DepartmentLink
                    v-if="link.department"
                    :department="link.department"
                    dep
                    bold
                  />
                  <PeopleLink v-else :person="link.person" bold />
                </div>
              </v-tooltip>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12">
              <div class="font-weight-bold">
                Debt permissions ({{ debtPermissions.length }}):
              </div>
              <v-chip
                v-for="permission in debtPermissions"
                :key="permission"
                class="ma-1"
              >
                {{ permission }}
              </v-chip>
            </v-col>
          </v-row>
        </v-window-item>
      </v-window>
    </v-form>
  </v-card-text>
</template>

<script>
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import PeopleLink from '@/components/links/PeopleLink.vue';
import { getPermissions } from '@/services/api/user.js';
import { useRoleAutocomplete } from '@/composables/autocomplete/role-autocomplete.js';
import { useDepartmentAutocomplete } from '@/composables/autocomplete/department-autocomplete.js';
import { useDebtAutocomplete } from '@/composables/autocomplete/debt-autocomplete.js';
import {
  addRoleToUser,
  removeRoleToUser,
  addDepartmentToUser,
  removeDepartmentToUser,
  addDebtToUser,
  removeDebtToUser,
} from '@/services/api/user.js';

export default {
  components: {
    DepartmentLink,
    PeopleLink,
  },
  props: {
    userId: {
      type: Number,
      default: null,
    },
    modelLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: { 'update:modelLoading': Boolean },
  setup() {
    return {
      ...useRoleAutocomplete(),
      ...useDepartmentAutocomplete(),
      ...useDebtAutocomplete(),
    };
  },
  data: () => ({
    access: {
      roles: [],
      departments: [],
      debts: [],
      permissions: [],
    },
    tab: 'roles',
  }),
  computed: {
    loading: {
      get() {
        return this.modelLoading;
      },
      set(newValue) {
        this.$emit('update:modelLoading', newValue);
      },
    },
    debtPermissions() {
      return this.access.permissions.filter((p) => p.match(/^debt\..*/));
    },
    canManageAllDepartments() {
      return this.access.permissions.includes('admin.manage_all_departments');
    },
  },
  created() {
    this.loadPermissions();
  },
  methods: {
    loadPermissions() {
      getPermissions(this.userId).then((access) => {
        this.access = access;
      });
    },
    addRole() {
      addRoleToUser(this.userId, { role_id: this.roleModel }).then(() => {
        this.loadPermissions();
      });
    },
    removeRole(role) {
      removeRoleToUser(this.userId, { role_id: role.id }).then(() => {
        this.loadPermissions();
      });
    },
    addDepartment() {
      addDepartmentToUser(this.userId, {
        department_id: this.departmentModel,
      }).then(() => {
        this.loadPermissions();
      });
    },
    removeDepartment(department) {
      removeDepartmentToUser(this.userId, {
        department_id: department.department_id,
      }).then(() => {
        this.loadPermissions();
      });
    },
    addDebt() {
      addDebtToUser(this.userId, { debt_id: this.debtModel }).then(() => {
        this.loadPermissions();
      });
    },
    removeDebt(debt) {
      removeDebtToUser(this.userId, { debt_id: debt.id }).then(() => {
        this.loadPermissions();
      });
    },
  },
};
</script>
