import { useDebounceRequest } from './debounce-request.js';
import { getEmployee } from '../../services/api/search.js';

/**
 * @param {import('./debounce-request.js').DebounceConfig} [config]
 * @returns {import('./debounce-request.js').DebounceResponse}
 */
export function useEmployeeAutocomplete(config = {}) {
  /** @type {import('./debounce-request.js').searchRequest} */
  const searchRequest = (abortSignal, search, id) => {
    const axiosConfig = {
      signal: abortSignal.signal,
    };

    getEmployee({ search, id }, axiosConfig)
      .then((users) => {
        employees.push(
          ...users.map((user) => {
            return {
              title: `${user.person.firstname} ${user.person.lastname}`,
              value: parseInt(user.user_id),
            };
          })
        );
      })
      .finally(() => (loadingEmployees.value = false));
  };

  const {
    attrs,
    listeners,

    modelValue: employeeModel,
    itemSearch: employeeSearch,
    items: employees,
    loading: loadingEmployees,
    noDataText: noEmployeeDataText,
    setPreloadValue: setPreselectedEmployee,
  } = useDebounceRequest(searchRequest, config);

  return {
    attrs,
    listeners,

    employeeModel,
    employees,
    employeeSearch,
    loadingEmployees,
    noEmployeeDataText,
    setPreselectedEmployee,
  };
}
