<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col v-if="isCreateMode" cols="12">
          <v-autocomplete
            v-model="principal"
            v-model:search="principalSearch"
            :items="principals"
            :loading="loadingPrincipals"
            :no-data-text="noPrincipalDataText"
            :error-messages="errors.department_id || errors.person_id"
            label="Principal"
            no-filter
            clearable
          />
        </v-col>

        <v-col v-if="isCreateMode" cols="12">
          <v-autocomplete
            v-model="impound.authorized_by"
            :items="impoundAuthorizers"
            :loading="loadingImpoundAuthorizers"
            :error-messages="errors.authorized_by"
            :hint="
              impound.department_id
                ? null
                : 'Choose a department as principal to filter authorizer.'
            "
            :disabled="!impound.department_id"
            no-data-text="No authorizer linked to the principal."
            label="Authorized by"
            clearable
            persistent-hint
          />
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-model="impound.impound_by"
            :loading="loadingEmployees"
            :items="employees"
            :error-messages="errors.impound_by"
            no-data-text="No employee found."
            label="Impound by"
          />
        </v-col>

        <v-col v-if="isCreateMode" cols="12">
          <v-text-field
            v-model="impound.date"
            :error-messages="errors.date"
            label="Impound date"
            type="date"
          />
        </v-col>

        <v-col cols="12">
          <v-autocomplete
            v-model="impound.reason_id"
            :items="reasons"
            :loading="loadingReasons"
            :error-messages="errors.reason_id"
            label="Reason"
          />
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="impound.cancel"
            :error-messages="errors.cancel"
            label="Cancelled?"
          />
        </v-col>

        <v-col v-if="impound.cancel" cols="12">
          <v-textarea
            v-model="impound.cancel_reason"
            :error-messages="errors.cancelled_reason"
            label="Cancelled reason"
          />
        </v-col>

        <v-col v-if="isCreateMode" cols="12">
          <v-text-field
            v-model="impound.price_day"
            :error-messages="errors.price_day"
            label="Price per day"
            type="number"
            :disabled="impound.default_price"
          />
        </v-col>

        <v-col v-if="isCreateMode" cols="12">
          <v-checkbox
            v-model="impound.default_price"
            :error-messages="errors.default_price"
            label="Default price?"
          />
        </v-col>

        <v-col v-if="!impound.no_tow" cols="12">
          <v-autocomplete
            v-model="impound.tow_by"
            :items="towingCompanies"
            :loading="loadingTowingCompanies"
            :error-messages="errors.tow_by"
            label="Tow by"
          />
        </v-col>

        <v-col cols="12">
          <v-checkbox
            v-model="impound.no_tow"
            :error-messages="errors.no_tow"
            label="No tow?"
          />
        </v-col>

        <div
          v-if="errorMessage"
          class="position-fixed"
          style="top: 20px; right: 10%; width: 80%"
        >
          <v-alert :text="errorMessage" elevation="8" type="error" />
        </div>

        <v-btn color="success" block @click="submit">Save</v-btn>
      </v-row>
    </v-form>
  </v-container>
</template>

<script setup>
import { ref, reactive, computed, watch, getCurrentInstance } from 'vue';

import { useLinkAutocomplete } from '@/composables/autocomplete/link-autocomplete.js';
import { useLoadingForm } from '@/composables/form/loadingForm.js';

import {
  createImpound,
  getImpound,
  updateImpound,
} from '@/services/api/impound.js';
import {
  useReasonOptions,
  useEmployeeOptions,
  useTowingCompanyOptions,
  useImpoundAuthorizerOptions,
} from '@/composables/form/optionsForm.js';

const props = defineProps({
  loadingValue: Boolean,
  impoundId: Number,
  vehicleId: Number,
});

const emits = defineEmits([
  'update:loadingValue',
  'error',
  'created',
  'updated',
]);

const {
  link: principalObject,
  linkModel: principal,
  linkSearch: principalSearch,
  links: principals,
  loadingLinks: loadingPrincipals,
  noLinkDataText: noPrincipalDataText,
} = useLinkAutocomplete();

const errorMessage = ref(null);
const errors = reactive({});
const impound = reactive({
  department_id: null,
  person_id: null,
  authorized_by: null,
  impound_by: null,
  date: null,
  reason_id: null,
  cancel: false,
  cancel_reason: null,
  price_day: null,
  default_price: true,
  tow_by: null,
  no_tow: false,
});

const vm = getCurrentInstance();
const { loading } = useLoadingForm(props.loadingValue, vm);
const { loadReasons, loadingReasons, reasons } = useReasonOptions({
  impound: true,
});
const { employees, loadEmployees, loadingEmployees } = useEmployeeOptions();
const { loadTowingCompanies, loadingTowingCompanies, towingCompanies } =
  useTowingCompanyOptions();
const {
  impoundAuthorizers,
  loadImpoundAuthorizers,
  loadingImpoundAuthorizers,
} = useImpoundAuthorizerOptions();

const isEditMode = computed(() => props.impoundId !== null);
const isCreateMode = computed(() => !isEditMode.value);

watch(principal, () => {
  impound.person_id = null;
  impound.department_id = null;

  const type = principalObject?.type;
  const id = principalObject?.id;

  if (!type || !id) {
    return;
  }

  if (type == 'person') {
    impound.person_id = id;
    impound.authorized_by = null;
  } else {
    impound.department_id = id;
    loadImpoundAuthorizers(id);
  }
});

function loadImpound() {
  loading.value = true;

  getImpound(props.impoundId, impound)
    .then((i) => {
      Object.assign(impound, i);
      impound.cancel = !!impound.date;
      impound.no_tow = !impound.tow_by;
    })
    .finally(() => {
      loading.value = false;
    });
  loading.value = true;
}

function storeImpound() {
  loading.value = true;
  let payload = { ...impound, vehicle_id: props.vehicleId };

  createImpound(payload)
    .then((impound) => {
      location.href = `/impounds/${impound.id}`;
      emits('created', impound);
    })
    .catch((error) => {
      processError(error);
    })
    .finally(() => {
      loading.value = false;
    });
}

function updateImpoundData() {
  loading.value = true;
  let payload = { ...impound };

  updateImpound(props.impoundId, payload)
    .then((impound) => {
      emits('updated', impound);
      location.href = `/impounds/${impound.id}`;
    })
    .catch((error) => {
      processError(error);
    })
    .finally(() => {
      loading.value = false;
    });
}

/**
 *
 * @param error
 */
function processError(error) {
  const { response } = error;

  if (response?.status === 422) {
    emits('error', response.data);
    Object.assign(errors, response.data.errors);
    errorMessage.value = response.data.message;

    return setTimeout(() => {
      errorMessage.value = null;
    }, 5000);
  }

  throw error;
}

function submit() {
  if (isCreateMode.value) {
    storeImpound();
  }

  if (isEditMode.value) {
    updateImpoundData();
  }
}

loadEmployees();
loadReasons();
loadTowingCompanies();
if (isEditMode.value) {
  loadImpound();
}
</script>
