import { getReasons } from '@/services/api/administration';
import { getTowingCompanies } from '@/services/api/company';
import { getDepartmentAuthorizer } from '@/services/api/department';
import { getEmployees } from '@/services/api/user';
import { reactive, ref } from 'vue';

export const useReasonOptions = (reasonSettings) => {
  let reasons = reactive([]);
  const loadingReasons = ref(false);

  const loadReasons = () => {
    loadingReasons.value = true;
    getReasons(reasonSettings)
      .then((_reasons) => {
        reasons.push(
          ..._reasons.map((reason) => {
            let reasonPrefix = reason.reason_code
              ? reason.reason_code + ' | '
              : '';

            return {
              title: reasonPrefix + reason.reason_title,
              value: reason.reason_id,
            };
          })
        );
      })
      .finally(() => {
        loadingReasons.value = false;
      });
  };

  return { loadReasons, loadingReasons, reasons };
};

export const useEmployeeOptions = () => {
  let employees = reactive([]);
  const loadingEmployees = ref(false);

  const loadEmployees = () => {
    loadingEmployees.value = true;
    employees.splice(0);

    getEmployees()
      .then((users) => {
        employees.push(
          ...users.map((user) => ({
            title: user.person.firstname + ' ' + user.person.lastname,
            value: user.id,
          }))
        );
      })
      .finally(() => {
        loadingEmployees.value = false;
      });
  };

  return { loadEmployees, loadingEmployees, employees };
};

export const useTowingCompanyOptions = () => {
  let towingCompanies = reactive([]);
  const loadingTowingCompanies = ref(false);

  const loadTowingCompanies = () => {
    loadingTowingCompanies.value = true;
    towingCompanies.splice(0);

    getTowingCompanies()
      .then((companies) => {
        towingCompanies.push(
          ...companies.map((company) => ({
            title: company.handle_name,
            value: company.id,
          }))
        );
      })
      .finally(() => {
        loadingTowingCompanies.value = false;
      });
  };

  return { loadTowingCompanies, loadingTowingCompanies, towingCompanies };
};

export const useImpoundAuthorizerOptions = () => {
  let impoundAuthorizers = reactive([]);
  const loadingImpoundAuthorizers = ref(false);

  const loadImpoundAuthorizers = (department_id) => {
    if (!department_id) {
      return;
    }

    loadingImpoundAuthorizers.value = true;
    impoundAuthorizers.splice(0);
    getDepartmentAuthorizer(department_id)
      .then((persons) => {
        impoundAuthorizers.push(
          ...persons.map((person) => ({
            title: person.firstname + ' ' + person.lastname,
            value: person.id,
          }))
        );
      })
      .finally(() => {
        loadingImpoundAuthorizers.value = false;
      });
  };

  return {
    loadImpoundAuthorizers,
    loadingImpoundAuthorizers,
    impoundAuthorizers,
  };
};
