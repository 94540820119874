<template>
  <v-card>
    <v-data-table-server
      v-bind="tableAttrs"
      fixed-footer
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title class="d-print-none">
          <v-row>
            <v-col cols="12" md="6">Impounding</v-col>

            <v-col cols="12" md="6" class="text-right">
              <v-btn
                v-tooltip="'Search'"
                variant="text"
                type="submit"
                form="impoundFilter"
                size="small"
                color="blue"
                icon="mdi-magnify"
              />

              <v-btn
                v-can="'impound.request'"
                v-tooltip="'Request impound'"
                variant="text"
                size="small"
                color="green"
                href="/impounds/request"
                icon="mdi-plus"
              />

              <v-btn
                v-tooltip="filterExpand ? 'Hide filter' : 'Show filter'"
                variant="text"
                size="small"
                icon="mdi-filter"
                :color="filterExpand ? 'orange' : 'blue'"
                @click="filterExpand = !filterExpand"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-form
              v-show="filterExpand"
              id="impoundFilter"
              @submit.prevent="fetchItems"
            >
              <v-row>
                <v-col cols="12" md="3">
                  <v-text-field v-model="search.ref_num" label="Ref. no." />
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
        </v-card-title>
      </template>

      <template #[`item.ref_num`]="{ item }">
        <a
          class="text-reset font-weight-bold text-nowrap"
          :href="route('impound.view', item.id)"
        >
          {{ item.ref_num }}
        </a>
      </template>

      <template #[`item.principal`]="{ item }">
        <PeopleLink v-if="item.person" :person="item.person" bold />
        <DepartmentLink
          v-if="item.department"
          :department="item.department"
          bold
        />
      </template>

      <template #[`item.authorizedBy`]="{ item }">
        <PeopleLink :person="item.authorizedBy" bold />
      </template>

      <template #[`item.vehicle`]="{ item }">
        {{ vehicleText(item.vehicle) || 'Not linked yet' }}
        <v-tooltip
          activator="parent"
          location="top"
          :text="vehicleTooltip(item.vehicle) || 'Not linked yet'"
        />
      </template>

      <template #[`item.progress`]="{ item }">
        <v-progress-linear :model-value="item.progress * 100" />
      </template>
    </v-data-table-server>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import DepartmentLink from '@/components/links/DepartmentLink.vue';
import usePaginationTable from '@/composables/table/table-pagination.js';
import { getImpounds } from '@/services/api/impound.js';
import { ref } from 'vue';

const filterExpand = ref(false);

const search = ref({
  ref_num: '',
});

const headers = [
  { title: 'Ref. no.', key: 'ref_num' },
  { title: 'Principal', key: 'principal', sortable: false },
  { title: 'Authorized by', key: 'authorizedBy', sortable: false },
  { title: 'Vehicle', key: 'vehicle', sortable: false },
  { title: 'Date created', key: 'created_at', align: 'end' },
  { title: 'Progress', key: 'progress', align: 'end', sortable: false },
];

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers: headers,
  sortBy: [{ key: 'ref_num', order: 'desc' }],
  fetchRequest: getImpounds,
  prepareSearch() {
    return {
      ...search.value,
    };
  },
});

/**
 * @param {object|null} vehicle
 * @returns {string|null}
 */
function vehicleText(vehicle) {
  if (!vehicle) {
    return null;
  }

  return `${vehicle.make}; ${vehicle.model}; ${vehicle.color}; (${vehicle.year})`;
}

/**
 * @param {object|null} vehicle
 * @returns {string|null}
 */
function vehicleTooltip(vehicle) {
  if (!vehicle) {
    return null;
  }

  return `${vehicle.chassis_number}; ${vehicle.engine_number}`;
}
</script>
