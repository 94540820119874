<template>
  <v-form>
    <v-row v-if="errorMessage">
      <v-col cols="12">
        <v-alert type="error">{{ errorMessage }}</v-alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="make"
          :items="makes"
          :error-messages="errors.make"
          clearable
          label="Make"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="form.model"
          :items="models"
          :return-object="false"
          :error-messages="errors.model"
          :no-data-text="form.make ? 'No models found' : 'Select make first'"
          item-value="title"
          clearable
          label="Model"
        />
      </v-col>

      <v-col cols="12" lg="6">
        <v-text-field
          v-model="form.specification"
          :error-messages="errors.specification"
          label="Specification"
        />
      </v-col>

      <v-col cols="12" lg="6">
        <v-text-field
          v-model="form.engine_number"
          :error-messages="errors.engine_number"
          label="Engine number"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="form.chassis_number"
          :error-messages="errors.chassis_number"
          label="Chassis number"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="form.year"
          :error-messages="errors.year"
          type="number"
          label="Manufacturing year"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="form.vehicle_type"
          :items="vehicle_types"
          :error-messages="errors.vehicle_type"
          clearable
          label="Type"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="form.fuel_type"
          :items="fuel_types"
          :error-messages="errors.fuel_type"
          clearable
          label="Fuel type"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="form.color"
          :items="colors"
          :error-messages="errors.color"
          clearable
          label="Color"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="form.gear_type"
          :items="gearboxes"
          :error-messages="errors.gear_type"
          clearable
          label="Gearbox"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="form.extra_info"
          :error-messages="errors.extra_info"
          label="Extra info"
        />
      </v-col>

      <v-overlay
        v-model="loading"
        class="align-center justify-center"
        contained
      >
        <v-progress-circular color="primary" indeterminate size="100" />
      </v-overlay>

      <v-btn color="success" block @click="submit">Save</v-btn>
    </v-row>
  </v-form>
</template>

<script setup>
import { useErrorsForm } from '@/composables/form/errorsForm.js';
import {
  getVehicleModels,
  createVehicle,
  getVehicle,
  updateVehicle,
} from '@/services/api/vehicle.js';
import {
  getColors,
  getFuelTypes,
  getMakes,
  getVehicleTypes,
  getGearboxes,
} from '@/store/vehicle/vehicle.js';
import { ref, computed, watch, onMounted } from 'vue';

const { vehicleId } = defineProps({
  vehicleId: {
    type: Number,
    required: false,
    default: null,
  },
});

const loading = defineModel('loading', {
  type: Boolean,
  default: false,
});

const emit = defineEmits(['error', 'created', 'updated']);

const make = ref(null);
const form = ref({
  make: null,
  model: null,
  specification: null,
  engine_number: null,
  chassis_number: null,
  year: null,
  vehicle_type: null,
  fuel_type: null,
  color: null,
  gear_type: null,
  extra_info: null,
});
const models = ref([]);
const loadingModels = ref(false);
const isEditMode = computed(() => vehicleId !== null);
const isCreateMode = computed(() => !isEditMode.value);

const _gearboxes = getGearboxes();
const gearboxes = computed(() =>
  _gearboxes.value.map((gear_type) => ({
    value: gear_type.id,
    title: gear_type.name,
  }))
);

const _makes = getMakes();
const makes = computed(() =>
  _makes.value.map((make) => ({ value: make.id, title: make.name }))
);

const _colors = getColors();
const colors = computed(() => _colors.value.map((color) => color.name));

const _fuel_types = getFuelTypes();
const fuel_types = computed(() =>
  _fuel_types.value.map((fuel_type) => ({
    value: fuel_type.id,
    title: fuel_type.name,
  }))
);

const _vehicle_types = getVehicleTypes();
const vehicle_types = computed(() =>
  _vehicle_types.value.map((vehicle_type) => ({
    value: vehicle_type.id,
    title: vehicle_type.name,
  }))
);

const { errors, errorMessage, setErrorsFromResponse, clearErrors } =
  useErrorsForm();

function loadVehicle() {
  clearErrors();
  loading.value = true;

  getVehicle(vehicleId)
    .then((_vehicle) => {
      make.value = makes.value.find((m) => m.title === _vehicle.make);
      form.value = {
        make: make.value.value,
        model: null,
        specification: _vehicle.specification,
        engine_number: _vehicle.engine_number,
        chassis_number: _vehicle.chassis_number,
        year: _vehicle.year,
        vehicle_type: _vehicle.vehicle_type,
        fuel_type: _vehicle.fuel_type,
        color: _vehicle.color,
        gear_type: _vehicle.gear_type,
        extra_info: _vehicle.extra_info,
      };

      setTimeout(() => (form.value.model = _vehicle.model), 10);
    })
    .catch((e) => {
      emit('error', e);
    })
    .finally(() => {
      loading.value = false;
    });
}

function storeVehicle() {
  clearErrors();
  loading.value = true;

  createVehicle(form.value)
    .then((vehicle) => {
      emit('created', vehicle);
    })
    .catch((e) => {
      if (setErrorsFromResponse(e)) {
        emit('error', e);
        return;
      }

      throw e;
    })
    .finally(() => {
      loading.value = false;
    });
}

function updateVehicleData() {
  clearErrors();
  loading.value = true;

  updateVehicle(vehicleId, form.value)
    .then((vehicle) => {
      emit('updated', vehicle);
    })
    .catch((e) => {
      if (setErrorsFromResponse(e)) {
        emit('error', e);
        return;
      }

      throw e;
    })
    .finally(() => {
      loading.value = false;
    });
}

function submit() {
  if (isCreateMode.value) {
    storeVehicle();
  }

  if (isEditMode.value) {
    updateVehicleData();
  }
}

function fetchModels() {
  form.value.model = null;
  models.value = [];

  const makeId = make.value?.value || make.value;

  if (!parseInt(makeId)) {
    form.value.model = null;
    return;
  }

  loadingModels.value = true;

  getVehicleModels(makeId)
    .then((m) => {
      models.value = m.map((model) => ({ value: model.id, title: model.name }));
    })
    .finally(() => {
      loadingModels.value = false;
    });
}

watch(make, (_make) => {
  const makeId = _make?.value || _make;

  if (!parseInt(makeId)) {
    form.value.model = null;
    return;
  }

  form.value.make = makes.value.find((_make) => _make.value === makeId)?.title;

  fetchModels();
});

onMounted(() => {
  if (isEditMode.value) {
    loadVehicle();
  }
});
</script>
