<template>
  <v-row>
    <v-col cols="12">
      <v-table>
        <thead>
          <tr>
            <th>Service</th>
            <th>Price</th>
            <th>Note</th>
          </tr>
        </thead>

        <tbody>
          <template v-for="(service, index) in model.services" :key="index">
            <tr>
              <td>{{ service.name }}</td>
              <td>{{ service.price }}</td>
              <td>{{ service.note || '-' }}</td>
            </tr>
          </template>
        </tbody>
      </v-table>
    </v-col>

    <v-col cols="12">
      <v-alert v-if="errorMessage" type="error">{{ errorMessage }}</v-alert>
    </v-col>

    <v-col cols="12">
      <v-autocomplete
        v-bind="servicesAttrs"
        label="Service"
        clearable
        v-on="servicesListeners"
      />
    </v-col>

    <v-col cols="12">
      <v-text-field v-model="price" label="Price" />
    </v-col>

    <v-col cols="12">
      <v-text-field v-model="note" label="Notes" />
    </v-col>

    <v-col cols="12">
      <v-btn color="blue" class="float-right" @click="addService">
        Add service
      </v-btn>
    </v-col>
  </v-row>
</template>

<script setup>
import { useServiceAutocomplete } from '@/composables/autocomplete/service-autocomplete.js';
import { stepProps } from '@/composables/task/stepOptions.js';
import { ref, watch } from 'vue';

const price = ref(null);
const note = ref(null);
const errorMessage = ref(null);

defineProps({
  ...stepProps,
});

const model = defineModel({ required: true, type: Object });
model.value.services = [];

const { attrs: servicesAttrs, listeners: servicesListeners } =
  useServiceAutocomplete({
    minSearchLength: 1,
    extraFields: { storage: true },
  });

watch(
  () => servicesAttrs.modelValue,
  () => {
    let service = servicesAttrs.items.find(
      (service) => service.value === servicesAttrs.modelValue
    );

    price.value = service?.price;
  }
);

function addService() {
  if (!servicesAttrs.modelValue || !price.value) {
    errorMessage.value = 'Service and price are required';

    setTimeout(() => {
      errorMessage.value = null;
    }, 3000);

    return;
  }

  let name = servicesAttrs.items.find(
    (service) => service.value === servicesAttrs.modelValue
  ).title;

  model.value.services.push({
    service_id: servicesAttrs.modelValue,
    name: name,
    note: note.value,
    price: price.value,
  });

  servicesAttrs.modelValue = null;
  price.value = null;
  note.value = null;
}
</script>
