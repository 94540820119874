<template>
  <v-card>
    <v-card-title class="text-center">
      Vehicle Information

      <span v-can="'vehicle.view'" class="float-right">
        <v-btn
          v-if="vehicle.id"
          v-tooltip="'Vehicle profile'"
          icon="mdi-car"
          :href="route('vehicles.show', [vehicle.id])"
        />
      </span>
    </v-card-title>
    <v-card-text>
      <v-table density="compact">
        <tbody>
          <tr>
            <th>Make</th>
            <td>{{ vehicle.make }}</td>
          </tr>

          <tr>
            <th>Model</th>
            <td>{{ vehicle.model }}</td>
          </tr>

          <tr>
            <th>Type</th>
            <td>{{ vehicle.type }}</td>
          </tr>

          <tr>
            <th>Gearbox</th>
            <td>{{ vehicle.gearbox }}</td>
          </tr>

          <tr v-if="vehicle.specification">
            <th>Specifications</th>
            <td>{{ vehicle.specification }}</td>
          </tr>

          <tr v-if="vehicle.chassis_number">
            <th>VIN</th>
            <td>{{ vehicle.chassis_number }}</td>
          </tr>

          <tr v-if="vehicle.engine_number">
            <th>Engine number</th>
            <td>{{ vehicle.engine_number }}</td>
          </tr>

          <tr>
            <th>Color</th>
            <td>{{ vehicle.color }}</td>
          </tr>

          <tr>
            <th>Year</th>
            <td>{{ vehicle.year }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { getVehicle } from '@/services/api/vehicle.js';
import { reactive, watch } from 'vue';

const props = defineProps({
  vehicleId: {
    type: Number,
    default: null,
  },
});

const vehicle = reactive({});

function fetchVehicle() {
  if (!props.vehicleId) {
    return;
  }

  getVehicle(props.vehicleId).then((v) => {
    Object.assign(vehicle, v);
  });
}

watch(
  () => props.vehicleId,
  () => fetchVehicle()
);

fetchVehicle();
</script>
