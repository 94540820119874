const emptyHTMLContent = (element) => {
  element.innerHTML = '';
};

const hide = ($element) => {
  if (!$element || $element.length == 0) return;
  if ($element.length) {
    for (let element = 0; element < $element.length; element++) {
      $element[element].classList.add('d-none');
    }
  } else {
    $element.classList.add('d-none');
  }
};

const show = ($element) => {
  if (!$element || $element.length == 0) return;
  if ($element.length) {
    for (let element = 0; element < $element.length; element++) {
      $element[element].classList.remove('d-none');
    }
  } else {
    $element.classList.remove('d-none');
  }
};

export { emptyHTMLContent, hide, show };
