const personLink = (person) => {
  if (!person?.person_id) {
    return '';
  }

  return `
  <a class="text-dark" href="/people/${person.person_id}">
    ${person.firstname} ${person.middlename || ''}
    <span class="font-weight-bold">${person.lastname}</span>
  </a>`;
};

const departmentLink = (department) => {
  if (department.handle_name) {
    return legacyDepartmentLink(department);
  }

  return `
  <a class="text-dark" href="/companies/${department.company_id}/departments/${department.department_id}">
    <span class="font-weight-bold">${department.company.handle_name}</span> <span class="d-inline-block">[${department.department}]</span>
  </a>`;
};

const legacyDepartmentLink = (company) => {
  return `
  <a class="text-dark" href="/companies/${company.company_id}/departments/${company.department_id}">
    <span class="font-weight-bold">${company.handle_name}</span> <span class="d-inline-block">[${company.department}]</span>
  </a>`;
};

const companyLink = (company) => {
  return `
  <a class="text-dark" href="/companies/${company.company_id}">
    <span class="font-weight-bold">${company.handle_name}</span>
  </a>`;
};

export { personLink, departmentLink, companyLink, legacyDepartmentLink };
