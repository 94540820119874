import { useDebounceRequest } from './debounce-request.js';
import { reactive, watch } from 'vue';
import { getPersonAndDepartment } from '../../services/api/search.js';

/**
 * @param {import('./debounce-request.js').DebounceConfig} [config]
 * @returns {object}
 */
export function useLinkAutocomplete(config = {}) {
  const searchRequest = (abortSignal, value) => {
    const axiosConfig = {
      signal: abortSignal.signal,
    };

    getPersonAndDepartment(value, axiosConfig)
      .then(({ departments, people }) => {
        links.push(
          ...departments.map((department) => {
            return {
              title: `${department.company.company_name} ${department.company.handle_name} [${department.department}]`,
              value: 'department:' + department.department_id,
            };
          })
        );
        links.push(
          ...people.map((person) => {
            return {
              title: `${person.firstname} ${person.lastname}`,
              value: 'person:' + person.person_id,
            };
          })
        );
      })
      .finally(() => (loadingLinks.value = false));
  };

  const {
    attrs,
    listeners,
    modelValue: linkModel,
    itemSearch: linkSearch,
    items: links,
    loading: loadingLinks,
    noDataText: noLinkDataText,
  } = useDebounceRequest(searchRequest, config);

  let link = reactive({ type: null, id: null });

  watch(linkModel, (value) => {
    const [type, id] = value?.split(':') || [];

    link.type = type;
    link.id = id && Number(id);
  });

  return {
    attrs,
    listeners,
    link,
    linkModel,
    links,
    linkSearch,
    loadingLinks,
    noLinkDataText,
  };
}
