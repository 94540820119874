import axios from '../../plugins/axios.js';

/**
 * @param {object} impound
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const createImpound = (impound, config = undefined) => {
  return axios
    .post('/api/v1/impounds', impound, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} impound_id
 * @param {object} request
 * @param {object} config
 * @returns {Promise<object>}
 */
export const getImpound = (impound_id, request, config = undefined) => {
  return axios
    .get('/api/v1/impounds/' + impound_id, request, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} impound_id
 * @param {object} impound
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const updateImpound = (impound_id, impound, config = undefined) => {
  return axios
    .patch('/api/v1/impounds/' + impound_id, impound, config)
    .then((response) => response.data.data);
};

/**
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const requestImpound = (request, config = undefined) => {
  return axios
    .post('/api/v1/impounds/request', request, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} impound_id
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getSteps = (impound_id, config = undefined) => {
  return axios
    .get('/api/v1/impounds/' + impound_id + '/steps', config)
    .then((response) => response.data.data);
};

/**
 * @param {object} request filters
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getImpounds = (request, config = undefined) => {
  return axios
    .get('/api/v1/impounds', { ...config, params: request })
    .then((response) => {
      return response.data;
    });
};
