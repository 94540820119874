<template>
  <v-form>
    <v-container>
      <v-row dense>
        <v-col cols="12" lg="3">
          <v-text-field
            v-model="form.ref_num"
            :error-messages="errors.ref_num"
            label="Referentienummer"
            :required="debt?.ref_num"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-text-field
            v-model="form.dossier"
            :error-messages="errors.dossier"
            label="Dossiernummer"
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-autocomplete
            v-model="form.physical_location"
            :error-messages="errors.physical_location"
            label="File location"
            :items="fileLocations"
            clearable
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-autocomplete
            v-model="employeeModel"
            v-model:search="employeeSearch"
            :items="employees"
            :loading="loadingEmployees"
            :no-data-text="noEmployeeDataText"
            label="Assignee"
            no-filter
            clearable
          />
        </v-col>
      </v-row>

      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            v-model="form.reason"
            :error-messages="errors.reason"
            label="Grondslag"
            :items="reasons"
            :loading="loadingReasons"
            :no-data-text="noDataTextReasons"
            clearable
            required
          />
        </v-col>
        <v-col cols="12" lg="3" dense>
          <v-switch
            v-model="dedicatedReasons"
            label="Dedicated grondslag"
            dense
          />
        </v-col>
      </v-row>

      <v-row v-if="isCreateMode" dense>
        <v-col cols="12" lg="3">
          <v-select
            v-model="form.responsibility"
            :error-messages="errors.responsibility"
            label="Rol"
            :items="responsibilities"
            required
          />
        </v-col>

        <v-col cols="12" lg="3">
          <v-select
            v-model="form.rental"
            :error-messages="errors.rental"
            label="Incasso / Regulieren"
            :items="debtTypes"
            required
          />
        </v-col>
      </v-row>

      <v-row v-if="isEditMode && debt" dense>
        <v-col cols="12">
          <DebtPenaltyForm :debt-id="debtId" />
        </v-col>

        <v-col cols="12">
          <DebtPresetForm
            v-model="form.preset"
            :debt="debt"
            :error-messages="errors.preset"
            calculations
          />
        </v-col>

        <!-- Total -->
        <v-col cols="12">
          <span class="font-weight-bold">Werkelijke RSI gedeelte:</span>
          <span class="float-right">{{ finalPercentage }}%</span>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="form.debt_note"
            label="Nota/Opmerking"
            :error-messages="errors.debt_note"
            maxlength="255"
            rows="3"
            placeholder="Hier komen alle opmerkingen over dit casus."
          />
        </v-col>
      </v-row>

      <v-btn color="success" block @click="submit">Opslaan</v-btn>
    </v-container>
  </v-form>
</template>

<script>
import DebtPresetForm from './DebtPresetForm.vue';
import DebtPenaltyForm from './DebtPenaltyForm.vue';
import { createDebt, getDebt } from '@/services/api/debt.js';
import { getReasons } from '@/services/api/administration.js';
import fileLocations from '@/data/fileLocations';
import { useEmployeeAutocomplete } from '@/composables/autocomplete/employee-autocomplete.js';

export default {
  components: {
    DebtPresetForm,
    DebtPenaltyForm,
  },
  props: {
    debtId: {
      type: [Number, String],
      default: null,
    },
    rental: {
      type: Boolean,
      default: null,
      required: false,
    },
    personId: {
      type: [Number, String],
      default: null,
    },
    departmentId: {
      type: [Number, String],
      default: null,
    },
    loadingValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['updated', 'created', 'update:loadingValue'],
  setup(props) {
    return {
      ...useEmployeeAutocomplete(),
    };
  },
  data: () => ({
    dedicatedReasons: false,
    debt: null,
    reasons: [],
    penalties: [],
    fileLocations,
    responsibilities: [
      { title: 'Crediteur', value: 4 },
      { title: 'Debiteur', value: 1 },
    ],
    debtTypes: [
      { title: 'Incasso', value: 0 },
      { title: 'Regulieren', value: 1 },
    ],
    loadingReasons: false,
    form: {
      preset: {
        rsi_part_time_scale: true,
        rsi_part: null,
        rsi_part_fixed: null,
        rsi_part_sum: null,
        recovery: null,
        recovery_fixed: null,
        recovery_sum: null,
        bank_cost: null,
        bank_cost_sum: null,
        interest_distribution_mode: null,
        rsi_interest_before: null,
        rsi_interest_before_sum: null,
        rsi_interest_after: null,
        rsi_interest_after_sum: null,
        rsi_penalty: null,
        rsi_penalty_sum: null,
      },
      ref_num: null,
      dossier: null,
      reason: null,
      responsibility: 4,
      rental: 0,
      debt_note: null,
      physical_location: null,
      assignee_id: null,
    },
    errors: {},
    errorMessage: null,
  }),
  computed: {
    loadingForm: {
      get() {
        return this.loadingValue;
      },
      set(value) {
        this.$emit('update:loadingValue', value);
      },
    },
    noDataTextReasons() {
      return this.loadingReasons ? 'Laden...' : 'Geen grondslagen gevonden';
    },
    isCreateMode() {
      return !this.debtId;
    },
    isEditMode() {
      return !!this.debtId;
    },
    isRental() {
      return (
        (this.isCreateMode && this.form.rental) ||
        (this.isEditMode && this.debt.rental)
      );
    },
    finalPercentage() {
      let percentage =
        parseFloat(
          this.debt?.calculations.grand_total > 0 &&
            (this.debt?.calculations.total_commission /
              this.debt?.calculations.grand_total) *
              100
        ) || 0;

      return percentage.toFixed(2);
    },
  },
  watch: {
    employeeModel(value) {
      this.form.assignee_id = value;
    },
    'form.rental'() {
      this.fetchReasons();
    },
    dedicatedReasons() {
      this.fetchReasons();
    },
  },
  mounted() {
    if (this.isCreateMode) {
      this.fetchReasons();
    }

    if (this.isEditMode) {
      this.fetchDebt();
    }
  },
  unmounted() {
    this.debt = null;
  },
  methods: {
    fetchDebt() {
      this.loadingForm = true;
      if (!Number.isInteger(parseInt(this.debtId))) {
        console.warn(`Debt id is not a number (${this.debtId})`);
        this.loadingForm = false;
        return;
      }

      getDebt(this.debtId)
        .then((debt) => {
          this.debt = debt;
          this.form = {
            ref_num: this.debt.ref_num,
            dossier: this.debt.dossier,
            reason: this.debt.reason,
            debt_note: this.debt.debt_note,
            physical_location: this.debt.physical_location,
            assignee_id: this.debt.assignee_id,
          };
          this.employees.splice(0);
          this.employeeModel = this.debt.assignee_id;

          if (this.debt.assignee) {
            let assignee = this.debt.assignee;

            this.employees.push({
              title: `${assignee.person.firstname} ${assignee.person.lastname}`,
              value: Number(this.debt.assignee_id),
            });
          }

          this.fetchReasons();
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    fetchReasons() {
      this.loadingReasons = true;
      this.reasons = [];

      getReasons({
        rental: this.isRental,
        debt: !this.isRental,
        dedicated: this.dedicatedReasons,
        reason_id: this.debt?.reason || '',
      })
        .then((reasons) => {
          reasons.forEach((reason) => {
            this.reasons.push({
              title: reason.reason_title,
              value: reason.reason_id,
            });
          });
        })
        .finally(() => {
          this.loadingReasons = false;
        });
    },
    submit() {
      if (this.isCreateMode) {
        this.storeDebt();
      }

      if (this.isEditMode) {
        this.updateDebt();
      }
    },
    updateDebt() {
      this.loadingForm = true;
      this.errors = {};

      axios
        .post(`/api/v1/debts/${this.debtId}`, {
          ...this.form,
          _method: 'PUT',
        })
        .then(({ data }) => {
          this.$emit('updated', data.data);
          location.reload();
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors;
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 5000);
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
    storeDebt() {
      this.loadingForm = true;
      this.errors = {};
      let payload = this.form;
      payload.person_id = parseInt(this.personId) || null;
      payload.department_id = parseInt(this.departmentId) || null;
      delete payload.preset;

      createDebt(payload)
        .then((debt) => {
          this.$emit('created', debt);
          location.href = '/debts/' + debt.debt_id;
        })
        .catch((error) => {
          let { response } = error;

          if (response.status === 422) {
            this.errors = response.data.errors;
            this.errorMessage = response.data.message;

            return setTimeout(() => {
              this.errorMessage = null;
            }, 5000);
          }

          throw error;
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>
