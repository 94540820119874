import axios from '../../plugins/axios.js';

/**
 * @param {number} id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorage = (id, request, config = undefined) => {
  return axios
    .get(route('api.v1.storages.show', [id]), request, config)
    .then((response) => response.data.data);
};

/**
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorages = (request, config = undefined) => {
  return axios
    .get(route('api.v1.storages.index'), { ...config, params: request })
    .then((response) => response.data);
};

/**
 * @param {number} storage_id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorageAttachments = (
  storage_id,
  request,
  config = undefined
) => {
  return axios
    .get(route('api.v1.storages.files.index', [storage_id]), request, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} storage_id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorageCosts = (storage_id, request, config = undefined) => {
  return axios
    .get(route('api.v1.storages.costs.index', [storage_id]), request, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} storage_id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorageNotes = (storage_id, request, config = undefined) => {
  return axios
    .get(route('api.v1.storages.notes.index', [storage_id]), request, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} storage_id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getStorageInvoices = (storage_id, request, config = undefined) => {
  return axios
    .get(route('api.v1.storages.invoices.index', [storage_id]), request, config)
    .then((response) => response.data.data);
};
