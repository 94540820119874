import { useDebounceRequest } from './debounce-request.js';
import { getDepartment } from '../../services/api/search.js';

/**
 * @param {import('./debounce-request.js').DebounceConfig} [config]
 * @returns {object}
 */
export function useDepartmentAutocomplete(config) {
  const searchRequest = (abortSignal, value) => {
    getDepartment(value, {
      signal: abortSignal.signal,
    })
      .then((_departments) => {
        departments.push(
          ..._departments.map((department) => {
            return {
              title: `${department.company.company_name} ${department.company.handle_name} [${department.department}]`,
              value: department.department_id,
            };
          })
        );
      })
      .finally(() => (loadingDepartments.value = false));
  };

  const {
    attrs,
    listeners,

    modelValue: departmentModel,
    itemSearch: departmentSearch,
    items: departments,
    loading: loadingDepartments,
    noDataText: noDepartmentDataText,
  } = useDebounceRequest(searchRequest, config);

  return {
    attrs,
    listeners,

    departmentModel,
    departments,
    departmentSearch,
    loadingDepartments,
    noDepartmentDataText,
  };
}
