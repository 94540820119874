const BEGIN_POSITION = 1;
const ARROW_UP_KEY = 38;
const ARROW_DOWN_KEY = 40;
const ENTER_KEY = 13;

export default function SearchDropdown(
  input,
  result_container,
  settingObject = {}
) {
  let that = this;
  that.settingObject = {
    drawOptions: (that) => {
      if (that.receivedData.person) {
        that.receivedData.person.forEach((person_i) => {
          that.result_container.append(
            `<li class="dropdown-item" data-option="${that.counter}" data-person-id="${person_i.person_id}">${person_i.firstname} ${person_i.middlename} ${person_i.lastname}</li>`
          );
          that.counter++;
        });
      }
      return that.counter;
    },
    selectOption: (that) => {
      let $selectedOption = window
        .$(that.result_container)
        .find(`[data-option="${that.selectPosition}"]`);
      let personId = $selectedOption.data('personId');
      document.querySelector('#ajax_person_id').value = personId
        ? personId
        : '';

      let returnData;
      if (personId) {
        returnData = {};
        returnData.person = Object.values(that.receivedData.person).filter(
          (person) => {
            return parseInt(personId) == parseInt(person.person_id);
          }
        );
      }
      return returnData;
    },
    sendData: (that) => {
      let sendData = {
        [that.setup.var_name]: that.search,
        searchId: that.searchId,
      };
      return sendData;
    },
    ...settingObject,
  };
  that.searchId = 0;
  that.lastRequest = 0;
  that.counter;
  that.receivedData;
  that.selectPosition = 0;
  that.result_container = window.$(`#${result_container}`);
  that.input = window.$(`#${input}`);
  this.dataResultContainer = (data) => {
    if (parseInt(data.searchId) > parseInt(that.lastRequest)) {
      that.lastRequest = data.searchId;
      that.receivedData = data;
      that.selectPosition = BEGIN_POSITION;
      if (that.receivedData) {
        that.drawContainer();
        that.moveUp();
        that.result_container.show('slow');
      } else {
        that.result_container.hide('slow');
      }
    }
  };

  this.drawContainer = () => {
    that.result_container.empty();
    that.selectPosition = BEGIN_POSITION;
    that.counter = 0;
    that.counter = that.settingObject.drawOptions(that);
  };

  this.isArrowKey = (e) => {
    return e.keyCode === ARROW_UP_KEY || e.keyCode === ARROW_DOWN_KEY;
  };

  this.isEnterKey = (e) => {
    return e.keyCode === ENTER_KEY;
  };

  this.moveUp = () => {
    if (that.receivedData && that.selectPosition > 0) {
      that.selectPosition--;
      that.updateActive();
    }
  };

  this.selectOption = (option = null, trigger = true) => {
    that.result_container.hide('slow');
    if (option) {
      that.selectPosition = option;
    }
    that.receivedData = that.settingObject.selectOption(that);
    that.lastRequest = that.searchId;
    that.selectPosition = 0;
    that.drawContainer();
    that.moveUp();
    that.input.val(
      window.$(that.result_container).find('[data-option="0"]').text()
    );
    if (trigger) that.input.blur().trigger('focusout');
  };

  this.updateActive = () => {
    that.result_container.find('li.active').removeClass('active');
    that.result_container
      .find(`li[data-option="${that.selectPosition}"]`)
      .addClass('active');
  };

  this.moveDown = () => {
    if (that.receivedData && that.counter > that.selectPosition + 1) {
      that.selectPosition++;
      that.updateActive();
    }
  };

  this.on = (setup) => {
    let $this = this;
    $this.setup = setup;
    window.$($this.input).on({
      keyup: function (e) {
        if ($this.isArrowKey(e) || $this.isEnterKey(e)) return;
        $this.search = e.target.value;
        $this.setup.minChar =
          $this.setup.minChar === 0 || $this.setup.minChar
            ? $this.setup.minChar
            : 2;
        $this.setup.var_name = $this.setup.var_name
          ? $this.setup.var_name
          : 'search';
        $this.searchId++;
        let sendData = $this.settingObject.sendData($this);
        if ($this.search.length > $this.setup.minChar) {
          window.$.post({
            url: setup.url,
            data: sendData,
            dataType: 'json',
          }).done(function (data) {
            $this.dataResultContainer(data, $this.search);
          });
        } else {
          $this.result_container.hide('slow');
          $this.receivedData = {};
          $this.drawContainer();
        }
      },
      keydown: function (e) {
        if (!$this.isArrowKey(e) && !$this.isEnterKey(e)) return;
        e.preventDefault();
        if (e.keyCode === ARROW_DOWN_KEY) {
          $this.moveDown();
        } else if (e.keyCode === ARROW_UP_KEY) {
          $this.moveUp();
        } else if (e.keyCode === ENTER_KEY) {
          $this.selectOption();
        }
      },
      focusin: function (e) {
        if (e.target.value.length > 0) $this.result_container.show('slow');
      },
      focusout: function () {
        if ($this.receivedData) $this.selectOption(null, false);
        else that.result_container.hide('slow');
      },
    });

    window.$($this.result_container).on({
      click: function (e) {
        $this.selectOption(e.target.dataset.option);
      },
      mouseenter: function () {
        window
          .$($this.result_container.children())
          .on('mouseenter', function (e) {
            $this.selectPosition = e.target.dataset.option;
            $this.updateActive();
          });
      },
      mouseleave: function () {
        window.$($this.result_container.children()).off('mouseenter');
      },
    });
  };
}
