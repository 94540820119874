import './bootstrap';
import VueGates from 'vue-gates';
// import { ZiggyVue } from 'ziggy-js';
// import { Ziggy } from './ziggy.js';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import { createApp } from 'vue';
import DebtFormModal from './components/external/modal/DebtFormModal.vue';
import ImpoundFormModal from './components/external/modal/ImpoundFormModal.vue';
import ChangePasswordModal from './components/external/modal/ChangePasswordModal.vue';
import ManageBulkSubscriptions from './components/external/modal/ManageBulkSubscriptions.vue';
import ManageSectionSubscriptions from './components/external/modal/ManageSectionSubscriptions.vue';
import DebtTable from './components/tables/debt/DebtTable.vue';
import RoleTable from './components/tables/user/RoleTable.vue';
import UserTable from './components/tables/user/UserTable.vue';
import LinksList from './components/general/LinksList.vue';
import NoteForm from './components/form/debt/note/NoteForm.vue';
import NavNotification from './components/menus/NavNotification.vue';
import RequestImpoundPage from './components/pages/storage/RequestImpoundPage.vue';
import ImpoundProfilePage from './components/pages/storage/ImpoundProfilePage.vue';
import StorageProfilePage from './components/pages/storage/StorageProfilePage.vue';
import GuestLayout from './components/layouts/GuestLayout.vue';
import AppLayout from './components/layouts/AppLayout.vue';
import LoginCard from './components/form/auth/LoginCard.vue';
import ImpoundTable from './components/tables/storage/ImpoundTable.vue';
import StorageTable from './components/tables/storage/StorageTable.vue';
import VehicleTable from './components/tables/vehicle/VehicleTable.vue';
import RepossessionTable from './components/tables/repossession/RepossessionTable.vue';
import ReprimandTable from './components/tables/reprimand/ReprimandTable.vue';
import VehicleFormModal from './components/modal/vehicle/VehicleFormModal.vue';
import UploadFileModal from './components/modal/general/UploadFormModal.vue';
import AttachmentCardModal from './components/modal/general/AttachmentCardModal.vue';

import { createVuetify } from 'vuetify';

const vuetify = createVuetify({
  defaults: {
    global: {
      density: 'compact',
      dense: true,
      hideDetails: 'auto',
      flat: true,
    },
    VBtn: {
      density: 'default',
    },
    VTextField: {
      variant: 'outlined',
    },
    VSelect: {
      variant: 'outlined',
    },
    VAutocomplete: {
      variant: 'outlined',
    },
    VCombobox: {
      variant: 'outlined',
    },
    VTextarea: {
      variant: 'outlined',
    },
  },
});

Bugsnag.start({
  apiKey: sharedData?.bugsnag,
  plugins: [new BugsnagPluginVue()],
  appVersion: sharedData?.version,
  releaseStage: sharedData?.env,
  user: {
    id: sharedData?.user?.id,
    name: sharedData?.user?.username,
    email: sharedData?.user?.email,
  },
});

const bugsnagVue = Bugsnag.getPlugin('vue');

const app = createApp({
  compatConfig: { MODE: 2 },
  beforeCreate() {
    this.$gates.setPermissions(sharedData?.gates?.permissions);
    this.$gates.setRoles(sharedData?.gates?.roles);
  },
});

app.config.compilerOptions.whitespace = 'preserve';
app.config.devtools = true;
app.config.globalProperties.route = route;

app
  .component('DebtFormModal', DebtFormModal)
  .component('ImpoundFormModal', ImpoundFormModal)
  .component('ChangePasswordModal', ChangePasswordModal)
  .component('ManageBulkSubscriptions', ManageBulkSubscriptions)
  .component('ManageSectionSubscriptions', ManageSectionSubscriptions)
  .component('DebtTable', DebtTable)
  .component('RoleTable', RoleTable)
  .component('UserTable', UserTable)
  .component('LinksList', LinksList)
  .component('NoteForm', NoteForm)
  .component('RequestImpoundPage', RequestImpoundPage)
  .component('ImpoundProfilePage', ImpoundProfilePage)
  .component('StorageProfilePage', StorageProfilePage)
  .component('GuestLayout', GuestLayout)
  .component('AppLayout', AppLayout)
  .component('LoginCard', LoginCard)
  .component('ImpoundTable', ImpoundTable)
  .component('StorageTable', StorageTable)
  .component('VehicleTable', VehicleTable)
  .component('RepossessionTable', RepossessionTable)
  .component('ReprimandTable', ReprimandTable)
  .component('NavNotification', NavNotification)
  .component('VehicleFormModal', VehicleFormModal)
  .component('UploadFileModal', UploadFileModal)
  .component('AttachmentCardModal', AttachmentCardModal)
  .use(VueGates)
  .use(vuetify)
  .use(bugsnagVue)
  // .use(ZiggyVue, Ziggy)
  .provide('sharedData', sharedData)
  .provide('$gates', app.gates)
  .provide('$vuetify', vuetify);

// For legacy support
window.tempVm = app.mount('#app');
