<template>
  <BaseModal v-model="openTaskModal" @close="openTaskModal = null">
    <TaskCard v-if="selectedTask" :task-id="selectedTask" />
  </BaseModal>

  <BaseModal v-model="openStepModal" @close="openStepModal = null">
    <TaskStepCard
      v-if="selectedStep"
      :task-id="selectedStep"
      @task-completed="stepCompleted"
    />
  </BaseModal>

  <v-card>
    <v-card-title class="text-center">Step and narrative</v-card-title>

    <v-card-text>
      <v-table>
        <tbody>
          <tr v-for="taskStep in steps" :key="taskStep.id">
            <th v-bind="openTaskAttrs(taskStep.id)">{{ taskStep.name }}</th>

            <td>
              <UserLink :user="taskStep.taskFor" bold />
            </td>

            <td class="text-right">
              {{ formatDate(taskStep.completed_at, 'fullDate') || 'Pending' }}
            </td>

            <td v-can:any="actionPermissions" class="text-right">
              <v-btn-group>
                <v-btn
                  v-if="!taskStep.completed_at"
                  v-can="'impound.task.complete'"
                  color="green"
                  icon="mdi-check"
                  @click="completeStep(taskStep)"
                />

                <v-btn
                  v-can="'impound.task.declaration.create'"
                  color="green"
                  icon="mdi-plus"
                  @click="addDeclaration(taskStep)"
                />
              </v-btn-group>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import UserLink from '@/components/links/UserLink.vue';
import TaskCard from '@/components/card/general/TaskCard.vue';
import BaseModal from '@/components/general/BaseModal.vue';
import TaskStepCard from '@/components/card/general/TaskStepCard.vue';

import { getSteps as getImpoundSteps } from '@/services/api/impound.js';
import { computed, ref, reactive, inject } from 'vue';
import { format as formatDate } from '@/utils/formatter/date.js';

const props = defineProps({
  sectionId: {
    type: Number,
    required: true,
  },

  section: {
    type: String,
    required: true,
  },
});

const emits = defineEmits(['step-completed']);

const $gates = inject('$gates');

const actionPermissions = computed(() => {
  switch (props.section) {
    case 'impound':
      return 'impound.task.complete|impound.task.declaration.create';
    default:
      return '';
  }
});

const steps = reactive([]);
const selectedTask = ref(null);
const openTaskModal = ref(false);
const selectedStep = ref(null);
const openStepModal = ref(false);

function fetchSteps() {
  if (props.section === 'impound') {
    getImpoundSteps(props.sectionId).then((s) => {
      Object.assign(steps, s);
    });
  }
}

/**
 * @param {object} step
 */
function openTask(step) {
  selectedTask.value = step.id;
  openTaskModal.value = true;
}

/**
 * @param {object} step
 */
function completeStep(step) {
  selectedStep.value = step.id;
  openStepModal.value = true;
}

/**
 * @param {object} step
 */
function addDeclaration(step) {
  console.log('addDeclaration', step);
}

function stepCompleted() {
  openStepModal.value = false;
  fetchSteps();
  emits('step-completed');
}

/**
 * @param {number} taskId
 */
function openTaskAttrs(taskId) {
  if (!$gates.hasPermission('impound.task.view')) {
    return {};
  }

  return {
    class: 'cursor-pointer',
    id: 'btn-task',
    ajaxtype: 'open',
    taskid: taskId,
  };
}
fetchSteps();
</script>
