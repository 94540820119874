<template>
  <a :class="textColor" :href="url">
    <span :class="{ 'd-inline-block': true, 'font-weight-bold': bold }">
      {{ company.handle_name }}
    </span>
  </a>
</template>

<script setup>
import { computed } from 'vue';

const { color, company } = defineProps({
  color: {
    type: String,
    default: null,
  },
  company: {
    type: Object,
    required: true,
  },
  bold: {
    type: Boolean,
    default: false,
  },
});

const textColor = computed(() => (color ? color : 'text-reset'));

const url = computed(() => `/companies/${company.id}`);
</script>
