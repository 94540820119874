import axios from '../../plugins/axios.js';
import $ from 'jquery';

const BEGIN_POSITION = null;
const ARROW_UP_KEY = 38;
const ARROW_DOWN_KEY = 40;
const ENTER_KEY = 13;

export default class SearchDropdown {
  callbacks = {
    drawOptions(options) {
      options.forEach((option, index) => {
        that.result_container.append(
          `<li class="dropdown-item" data-option="${index}" data-value="${option.value}">${option.name}</li>`
        );
      });

      return options.length;
    },

    buildData(data) {
      let options = [];

      if (data.person) {
        Object.values(data.person).forEach((person) => {
          options.push({
            name:
              person.firstname +
              ' ' +
              person.middlename +
              ' ' +
              person.lastname,
            value: person.person_id,
            type: 'person',
            field: 'person_id',
          });
        });
      }

      return options;
    },

    selectOption(that) {
      let $selectedOption = $(that.result_container).find(
        `[data-option="${that.selectPosition}"]`
      );

      that.selectedOption = that.options[$selectedOption.data.option];
      document.querySelector('#ajax_person_id').value = personId || '';
      if (personId) {
        let returnData = {};
        returnData.person = Object.values(that.receivedData.person).filter(
          (person) => {
            return parseInt(personId) == parseInt(person.person_id);
          }
        );
      }
      return returnData;
    },

    sendData(that) {
      let sendData = {
        [that.setup.var_name]: that.search,
      };
      return sendData;
    },
  };

  input;

  result_container;

  searchId = 0;

  lastRequest = 0;

  receivedData;

  options = [];

  /** @param {number} selectPosition */
  selectedOption = BEGIN_POSITION;

  /** @param {number} selectPosition */
  selectPosition = BEGIN_POSITION;

  /**
   * @param {string} input
   * @param {string} result_container
   * @returns {self}
   */
  constructor(input, result_container) {
    this.input = $(`#${input}`);
    this.result_container = $(`#${result_container}`);
    return this;
  }

  /**
   * @param {object} data
   * @returns {self}
   */
  dataResultContainer(data) {
    if (parseInt(data.searchId) > parseInt(this.lastRequest)) {
      this.lastRequest = data.searchId;
      this.receivedData = data;
      this.selectPosition = BEGIN_POSITION;

      if (this.receivedData) {
        this.drawContainer();
        this.moveUp();
        this.result_container.show('slow');

        return this;
      }

      this.result_container.hide('slow');
      return this;
    }
  }

  setCallbacks(callbacks) {
    this.callbacks = callbacks;

    return this;
  }

  drawContainer() {
    this.result_container.empty();
    this.selectPosition = BEGIN_POSITION;
    this.callbacks.drawOptions(this.options);
  }

  isArrowKey(e) {
    return e.keyCode === ARROW_UP_KEY || e.keyCode === ARROW_DOWN_KEY;
  }

  isEnterKey(e) {
    return e.keyCode === ENTER_KEY;
  }

  moveUp() {
    if (this.receivedData && this.selectPosition > 0) {
      this.selectPosition--;
      this.updateActive();
    }
  }

  selectOption(option = null, trigger = true) {
    this.result_container.hide('slow');
    if (option !== null) {
      this.selectPosition = option;
    }

    this.receivedData = this.callbacks.selectOption(this);
    this.lastRequest = this.searchId;
    this.selectPosition = 0;
    this.drawContainer();
    this.moveUp();
    this.input.val($(this.result_container).find('[data-option="0"]').text());
    if (trigger) this.input.blur().trigger('focusout');
  }

  updateActive() {
    this.result_container.find('li.active').removeClass('active');
    this.result_container
      .find(`li[data-option="${this.selectPosition}"]`)
      .addClass('active');
  }

  moveDown() {
    if (this.receivedData && this.options.length > this.selectPosition + 1) {
      this.selectPosition++;
      this.updateActive();
    }
  }

  listen(setup) {
    this.setup = setup;
    $(this.input).on({
      keyup: function (e) {
        if (this.isArrowKey(e) || this.isEnterKey(e)) return;
        this.search = e.target.value;
        this.setup.minChar =
          this.setup.minChar === 0 || this.setup.minChar
            ? this.setup.minChar
            : 2;
        this.setup.var_name = this.setup.var_name
          ? this.setup.var_name
          : 'search';
        this.searchId++;
        let sendData = this.callbacks.sendData(this);
        sendData['searchId'] = this.searchId;
        if (this.search.length > this.setup.minChar) {
          axios
            .request({
              method: setup.method || 'post',
              url: setup.url,
              data: sendData,
            })
            .then(function (data) {
              this.options = this.callbacks.buildData(data);
              this.dataResultContainer(this.options, this.search);
            });
        } else {
          this.result_container.hide('slow');
          this.receivedData = {};
          this.drawContainer();
        }
      },
      keydown: function (e) {
        if (!this.isArrowKey(e) && !this.isEnterKey(e)) return;
        e.preventDefault();
        if (e.keyCode === ARROW_DOWN_KEY) {
          this.moveDown();
        } else if (e.keyCode === ARROW_UP_KEY) {
          this.moveUp();
        } else if (e.keyCode === ENTER_KEY) {
          this.selectOption();
        }
      },
      focusin: function (e) {
        if (e.target.value.length > 0) this.result_container.show('slow');
      },
      focusout: function (e) {
        if (this.receivedData) this.selectOption(null, false);
        else that.result_container.hide('slow');
      },
    });

    $(this.result_container).on({
      click: function (e) {
        this.selectOption(e.target.dataset.option);
      },
      mouseenter: function () {
        $(this.result_container.children()).on('mouseenter', function (e) {
          if (e.target.dataset.option === this.selectPosition) return;
          this.selectPosition = e.target.dataset.option;
          this.updateActive();
        });
      },
      mouseleave: function () {
        $(this.result_container.children()).off('mouseenter');
      },
    });
  }
}
