export const stepProps = {
  task: {
    type: Object,
    required: true,
  },
  errors: {
    type: Object,
    required: false,
  },
};
