import axios from '../../plugins/axios.js';

/**
 * @param {number} taskId
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getTask = (taskId, config = undefined) => {
  return axios
    .get(`/api/v1/tasks/${taskId}`, config)
    .then((response) => response.data.data);
};

/**
 * @param {number} taskId
 * @param {object} data
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<import('axios').AxiosResponse>}
 */
export const completeStep = (taskId, data, config = undefined) => {
  return axios
    .post(`/api/v1/tasks/${taskId}/step/complete`, data, config)
    .then((response) => response.data.data);
};
