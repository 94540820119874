import { departmentLink, personLink } from './links';

const calculateTotalPaymentPercentage = (debt) => {
  let total_payment = parseFloat(debt.total_payment || 0);
  let finaltotal = parseFloat(debt.finaltotal || 0);

  if (parseFloat(debt.contract)) {
    finaltotal = parseFloat(debt.contract || 0) + total_payment;
  }

  return ((total_payment / parseFloat(finaltotal)) * 100).toFixed(2);
};

const isCreditor = (pivot) => {
  if (!pivot) {
    null;
  }

  return pivot.responsibility == 3 || pivot.responsibility == 4;
};

const isDebtor = (pivot) => {
  if (!pivot) {
    null;
  }

  return pivot.responsibility == 1 || pivot.responsibility == 2;
};

const debtorsInfo = (debt) => {
  let cell = '';

  debt?.people?.forEach((person) => {
    if (!isDebtor(person.pivot)) {
      return;
    }

    cell += `<div class="d-block w-100">${personLink(person)}</div>`;
  });

  debt?.departments?.forEach((department) => {
    if (!isDebtor(department.pivot)) {
      return;
    }
    cell += `<div class="d-block w-100">${departmentLink(department)}</div>`;
  });

  return cell;
};

const creditorsInfo = (debt) => {
  let cell = '';

  debt?.people?.forEach((person) => {
    if (!isCreditor(person.pivot)) {
      return;
    }

    cell += `<div class="d-block w-100">${personLink(person)}</div>`;
  });

  debt?.departments?.forEach((department) => {
    if (!isCreditor(department.pivot)) {
      return;
    }

    cell += `<div class="d-block w-100">${departmentLink(department)}</div>`;
  });

  return cell;
};

export { calculateTotalPaymentPercentage, debtorsInfo, creditorsInfo };
