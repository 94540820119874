import { useDebounceRequest } from './debounce-request.js';
import {
  getPerson,
  getDepartmentAuthorizer,
} from '../../services/api/search.js';

/**
 * @param {import('./debounce-request.js').DebounceConfig} [config]
 * @returns {object}
 */
export function usePersonAutocomplete(config = {}) {
  const searchRequest = (abortSignal, value) => {
    const axiosConfig = {
      signal: abortSignal.signal,
    };

    let promise = null;

    if (config?.type?.value === 'authorizer') {
      if (!config?.department_id?.value) {
        console.error('Department ID is required for authorizer type');
        return;
      }

      promise = getDepartmentAuthorizer(
        config.department_id.value,
        value,
        axiosConfig
      );
    } else {
      promise = getPerson(value, axiosConfig);
    }

    promise
      .then((_persons) => {
        people.push(
          ..._persons.map((person) => {
            return {
              title: `${person.firstname} ${person.lastname}`,
              value: person.id,
            };
          })
        );
      })
      .finally(() => (loadingPeople.value = false));
  };

  const {
    attrs,
    listeners,
    modelValue: personModel,
    itemSearch: personSearch,
    items: people,
    loading: loadingPeople,
    noDataText: noPersonDataText,
    setPreloadValue: setPreselectedPerson,
  } = useDebounceRequest(searchRequest, config);

  return {
    attrs,
    listeners,
    personModel,
    people,
    personSearch,
    loadingPeople,
    noPersonDataText,
    setPreselectedPerson,
  };
}
