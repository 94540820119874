<template>
  <v-dialog v-model="model" width="auto" transition="dialog-transition">
    <template v-if="!noButton" #activator="{ props }">
      <slot :props="props" name="activator">
        <v-btn color="red" size="x-small" v-bind="props">
          <VIcon icon="mdi-trash-can" />
        </v-btn>
      </slot>
    </template>

    <v-row>
      <v-col cols="12">
        <v-overlay
          v-model="loading"
          contained
          class="align-center justify-center"
        >
          <v-progress-circular color="primary" indeterminate size="100" />
        </v-overlay>

        <v-card>
          <v-card-title class="headline">Delete</v-card-title>

          <v-card-text>Do you really want to delete this item?</v-card-text>

          <v-card-actions>
            <v-btn color="grey" text @click="closeDialog">Cancel</v-btn>

            <v-btn color="red" variant="tonal" elevated @click="deleteItem">
              Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-dialog>
</template>

<script setup>
import axios from '@/plugins/axios.js';

const emit = defineEmits(['deleted']);

const { url } = defineProps({
  url: {
    type: String,
    required: true,
  },
  noButton: {
    type: Boolean,
    default: false,
  },
});

const model = defineModel({
  type: Boolean,
  default: false,
});

const loading = defineModel('loading', {
  type: Boolean,
  default: false,
});

function deleteItem() {
  loading.value = true;
  axios
    .post(url, { _method: 'delete' })
    .then((response) => {
      emit('deleted', response);
    })
    .finally(() => {
      model.value = false;
      loading.value = false;
    });
}

function closeDialog() {
  model.value = false;
}
</script>
