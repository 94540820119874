<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="text-center">
            <h2>Request Impound</h2>
          </v-card-title>

          <v-card-text>
            <v-form @submit.prevent="submit">
              <v-row v-if="errorMessage">
                <v-col cols="12">
                  <v-alert type="error">{{ errorMessage }}</v-alert>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="form.department_id"
                    label="Department"
                    :items="departments"
                    :disabled="departments.length == 1"
                    :error-messages="errors.department_id"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h5>Pickup Information</h5>
                </v-col>

                <v-col cols="8">
                  <v-combobox
                    v-model="streetModel"
                    v-model:search="streetSearch"
                    autocomplete="off"
                    :items="[
                      {
                        value: 0,
                        title: loadingStreets ? 'Loading...' : 'Unknown',
                      },
                      ...streets,
                    ]"
                    clearable
                    no-filter
                    :loading="loadingStreets"
                    :error-messages="errors.address"
                    item-value="title"
                    label="Address"
                  />
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="form.house_number"
                    label="Number"
                    :error-messages="errors.house_number"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h5>Vehicle Information</h5>
                </v-col>

                <v-col cols="4">
                  <v-combobox
                    v-model="make"
                    :items="makes"
                    :error-messages="errors.vehicle?.make"
                    clearable
                    label="Make"
                  />
                </v-col>
                <v-col cols="4">
                  <v-combobox
                    v-model="form.vehicle.model"
                    :items="models"
                    :return-object="false"
                    :error-messages="errors.vehicle?.model"
                    item-value="title"
                    clearable
                    label="Model"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.vehicle.year"
                    :error-messages="errors.vehicle?.year"
                    type="number"
                    label="Year"
                  />
                </v-col>

                <v-col cols="4">
                  <v-combobox
                    v-model="form.vehicle.type"
                    :items="types"
                    :return-object="false"
                    :error-messages="errors.vehicle?.type"
                    item-value="title"
                    clearable
                    label="Type"
                  />
                </v-col>
                <v-col cols="4">
                  <v-combobox
                    v-model="form.vehicle.color"
                    :items="colors"
                    :return-object="false"
                    :error-messages="errors.vehicle?.color"
                    item-value="title"
                    clearable
                    label="Color"
                  />
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="form.vehicle.plate_number"
                    label="Plate Number"
                    :error-messages="errors.vehicle?.plate_number"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="form.vehicle.chassis_number"
                    label="Chassis Number/VIN"
                    :error-messages="errors.vehicle?.chassis_number"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h5>Owner Information</h5>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="form.owner.name"
                    :error-messages="errors.owner?.name"
                    label="Name"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.owner.contact_number"
                    label="Contact Number"
                    :error-messages="errors.owner?.contact_number"
                  />
                </v-col>

                <v-col cols="12">
                  <v-checkbox
                    v-model="form.owner_is_driver"
                    label="Owner is the driver"
                    :error-messages="errors.owner_is_driver"
                  />
                </v-col>
              </v-row>

              <v-row v-if="!form.owner_is_driver">
                <v-col cols="12">
                  <h5>Driver Information</h5>
                </v-col>

                <v-col cols="4">
                  <v-text-field
                    v-model="form.driver.name"
                    :error-messages="errors.driver?.name"
                    label="Name"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    v-model="form.driver.contact_number"
                    label="Contact Number"
                    :error-messages="errors.driver?.contact_number"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <h5>Impound Information</h5>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="form.dossier"
                    label="Mutatienummer"
                    :error-messages="errors.dossier"
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="form.impound_by"
                    label="Impound By"
                    :error-messages="errors.impound_by"
                  />
                </v-col>

                <v-col cols="6">
                  <v-select
                    v-model="form.impound_type"
                    label="Impound type"
                    :items="[
                      { value: 1, title: 'Storage' },
                      { value: 2, title: 'Seizure' },
                    ]"
                    :error-messages="errors.impound_type"
                  />
                </v-col>

                <v-col cols="6">
                  <v-checkbox
                    v-model="form.tow_to_rsi"
                    label="Tow to RSI"
                    :error-messages="errors.tow_to_rsi"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-combobox
                    v-model="form.reason"
                    :items="[
                      { value: 'accident', title: 'Accident' },
                      { value: 'illegal_parking', title: 'Illegal Parking' },
                      { value: 'abandoned', title: 'Abandoned' },
                      { value: 'other', title: 'Other' },
                    ]"
                    :return-object="false"
                    :error-messages="errors.reason"
                    item-value="title"
                    clearable
                    label="Reason"
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-btn color="primary" :disabled="submitting" type="submit">
                    Submit
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, reactive, computed, watch, inject } from 'vue';

import { useStreetAutocomplete } from '@/composables/autocomplete/street-autocomplete.js';
import { requestImpound } from '@/services/api/impound.js';
import {
  getVehicleMakes,
  getVehicleModels,
  getVehicleTypes,
  getVehicleColors,
} from '@/services/api/vehicle.js';
import { useErrorsForm } from '@/composables/form/errorsForm';

const { streets, streetModel, streetSearch, loadingStreets } =
  useStreetAutocomplete();

const { errors, errorMessage, setErrorsFromResponse, clearErrors } =
  useErrorsForm();

const make = ref(null);

const form = reactive({
  department_id: null,
  address: null,
  house_number: null,
  vehicle: {
    plate_number: null,
    model: null,
    make: null,
    chassis_number: null,
    color: null,
    type: null,
    year: null,
  },
  owner_is_driver: false,
  owner: {
    name: null,
    contact_number: null,
  },
  driver: {
    name: null,
    contact_number: null,
  },
  tow_to_rsi: true,
  impound_by: null,
  dossier: null,
  impound_type: 1,
  reason: null,
});

const submitting = ref(false);
const { gates } = inject('sharedData');

const makes = ref([]);
const models = ref([]);
const loadingModels = ref(false);
const types = ref([]);
const colors = ref([]);

const departments = computed(() => {
  return (
    gates.departments?.map((department) => ({
      value: department.id,
      title: `${department.company.handle_name} [${department.name}]`,
    })) || []
  );
});

watch(make, (value) => {
  if (!parseInt(value?.value || value)) {
    return;
  }

  fetchModels();
});

function submit() {
  clearErrors();
  submitting.value = true;
  form.address = streetModel.value?.title || streetSearch.value;
  form.vehicle.make = make.value?.title || make.value;

  requestImpound(form)
    .then((impound) => {
      setTimeout(() => {
        submitting.value = true;
      }, 200);

      // redirect to impound page
      location.href = `/impounds/${impound.id}`;
    })
    .catch((error) => {
      if (setErrorsFromResponse(error)) {
        return;
      }

      throw error;
    })
    .finally(() => {
      submitting.value = false;
    });
}

function fetchColors() {
  colors.value = [];

  getVehicleColors().then((c) => {
    colors.value = c.map((color) => ({ value: color.id, title: color.name }));
  });
}

function fetchVehicleTypes() {
  types.value = [];

  getVehicleTypes().then((t) => {
    types.value = t.map((type) => ({ value: type.id, title: type.name }));
  });
}

function fetchMakes() {
  makes.value = [];

  getVehicleMakes().then((m) => {
    makes.value = m.map((make) => ({ value: make.id, title: make.name }));
  });
}

function fetchModels() {
  form.vehicle.model = null;
  models.value = [];

  const makeId = make.value?.value || make.value;

  if (!parseInt(makeId)) {
    return;
  }

  loadingModels.value = true;

  getVehicleModels(makeId)
    .then((m) => {
      models.value = m.map((model) => ({ value: model.id, title: model.name }));
    })
    .finally(() => {
      loadingModels.value = false;
    });
}

function updateDepartment() {
  if (departments.value.length === 1) {
    form.department_id = departments.value[0].value;
  }
}

fetchMakes();
fetchVehicleTypes();
fetchColors();

setTimeout(() => {
  updateDepartment();
}, 100);
</script>
