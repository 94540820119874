import { VueElement, computed } from 'vue';

/**
 * @param {import('vue').Ref<boolean>} loadingValue
 * @param {VueElement} context
 * @returns {{loading: import('vue').WritableComputedRef<boolean>}}
 */
export const useLoadingForm = (loadingValue, context) => {
  const loading = computed({
    get: () => loadingValue.value,
    set: (value) => context.emit('update:loadingValue', value),
  });

  return {
    loading,
  };
};
