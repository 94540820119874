<template>
  <v-card class="px-2">
    <v-card-title> Notulen </v-card-title>
    <v-alert v-if="hasLetter" type="error" class="font-weight-bold" closeable>
      TIN UN CARTA DEN CAYA!!!
    </v-alert>
    <hr />
    <form @submit.prevent="saveNoteRequest">
      <v-row>
        <template v-if="type == 'edit'">
          <v-col cols="12">
            <v-text-field
              v-model="note.note_date"
              type="date"
              :error-messages="errors.note_date"
              label="Date"
            />
          </v-col>

          <v-col v-if="note.storage_id" cols="12">
            <v-text-field
              v-model="note.note_time"
              type="time"
              :error-messages="errors.note_time"
              label="Time"
            />
          </v-col>
        </template>

        <v-col v-if="canChangeUltimatum" cols="12">
          <v-text-field
            v-model="note.ultimatum"
            type="date"
            :error-messages="errors.ultimatum"
            label="Nieuwe ultimatum"
          />
        </v-col>

        <v-col v-if="isSection" cols="12">
          <v-select
            v-model="note.phone_id"
            label="Telefoon nr. RSI"
            :items="phoneList"
            :error-messages="errors.phone_id"
            disabled
          />
        </v-col>

        <v-col cols="12">
          <v-textarea
            v-model="note.note_msg"
            label="Nota"
            :error-messages="errors.note_msg"
            maxlength="5000"
          />
        </v-col>

        <template v-if="canAddPeriod">
          <v-col cols="12" class="font-weight-bold text-center">
            Boetevrije periode
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="note.freepenalty_from"
              type="date"
              :error-messages="errors.freepenalty_from"
              label="Vanaf"
            />
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="note.freepenalty_to"
              type="date"
              :error-messages="errors.freepenalty_to"
              label="Tot"
            />
          </v-col>
        </template>

        <v-col v-if="canAddCost" cols="12">
          <v-autocomplete
            v-model="note.service_id"
            label="Kosten toevoegen"
            :items="services"
            :error-messages="errors.service_id"
          />
        </v-col>

        <v-col v-if="note.user_id" cols="12">
          <v-autocomplete
            v-model="note.user_id"
            v-model:search="employeeSearch"
            :error-messages="errors.user_id"
            :items="employees"
            :loading="loadingEmployees"
            :no-data-text="noEmployeeDataText"
            label="Uitgevoerd door"
            no-filter
            clearable
          />
        </v-col>

        <v-col v-if="canChangeStatus" cols="12">
          <v-select
            v-model="note.status"
            label="Status veranderen"
            :items="statuses"
            :error-messages="errors.status"
          />
        </v-col>

        <v-col v-if="isSection" cols="6">
          <v-checkbox
            v-model="note.pop_up"
            :error-messages="errors.pop_up"
            :value="true"
          >
            <template #label="attr">
              <v-btn
                color="warning"
                block
                v-bind="attr"
                @click="note.pop_up = !note.pop_up"
                >Pop Up</v-btn
              >
            </template>
          </v-checkbox>
        </v-col>

        <v-col cols="6">
          <v-btn type="submit" color="success" block>Save</v-btn>
        </v-col>

        <v-overlay
          v-model="overlay"
          contained
          persistent
          class="align-center justify-center"
        >
          <v-progress-circular
            size="128"
            width="12"
            indeterminate
            color="white"
          />
        </v-overlay>
      </v-row>
    </form>
  </v-card>
</template>
<script>
import { getServices } from '@/services/api/service.js';
import { getStatuses, saveNote } from '@/services/api/debt.js';
const showError = window.showError;

export default {
  props: {
    noteId: {
      type: [String, Number],
      default: null,
    },
    hasLetter: {
      type: Boolean,
      default: false,
    },
    relationId: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    canChangeUltimatum: {
      type: Boolean,
      default: false,
    },
    canAddPeriod: {
      type: Boolean,
      default: false,
    },
    canAddCost: {
      // return "edit_action_debt" | permission || "edit_reprimand" | permission || repossession_id
      type: Boolean,
      default: false,
    },
  },
  emits: ['note-saved'],
  data: () => ({
    note: {
      note_id: null,
      debt_id: null,
      reprimand_id: null,
      repossession_id: null,
      note_date: null,
      note_time: null,
      ultimatum: null,
      phone_id: null,
      note_msg: null,
      freepenalty_from: null,
      freepenalty_to: null,
      service_id: null,
      user_id: null,
      status: null,
      pop_up: false,
    },
    errors: {},
    overlay: false,
    statuses: [],
    phoneList: [
      { title: '5680101', value: 1 },
      { title: '5680111', value: 2 },
    ],
    employees: [],
    services: [],
  }),
  computed: {
    canChangeStatus() {
      return this.isSection; // && ajax_type != "edit"
    },
    isSection() {
      return ['debt', 'reprimand', 'repossession'].includes(this.type);
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    saveNoteRequest() {
      this.overlay = true;
      this.errors = {};

      saveNote(this.relationId, this.note)
        .then((response) => {
          this.$emit('note-saved', response);
          location.reload();
        })
        .catch((error) => {
          if (error.response.status != 422) {
            throw error;
          }

          this.errors = error.response.data.errors;
          showError(this.errors, true);
        })
        .finally(() => {
          this.overlay = false;
        });
    },
    fetchData() {
      getServices({ note: true, debt: true }).then((services) => {
        this.services = services.map((service) => ({
          title: service.service_name,
          value: service.service_id,
        }));
      });
      getStatuses({ automatic: false }).then((statuses) => {
        this.statuses = statuses.map((status) => ({
          title: status.status_name,
          value: status.status_id,
        }));
      });
    },
  },
};
</script>
