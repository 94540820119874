import { useDebounceRequest } from './debounce-request.js';
import { getDebt } from '../../services/api/search.js';

const responsibility = {
  debtor: 1,
  guarantor: 2,
  proxy: 3,
  creditor: 4,
};

const getLinkName = (link) => {
  if (link.department) {
    return (
      link.department.company.company_name +
      ' ' +
      link.department.company.handle_name +
      ' [' +
      link.department.department +
      ']'
    );
  }

  if (link.person) {
    return link.person.firstname + ' ' + link.person.lastname;
  }

  return '';
};

const buildLinksName = (links) => {
  let debtor = '';
  let creditor = '';

  links.forEach((link) => {
    if (!debtor && link.responsibility === responsibility.debtor) {
      debtor = getLinkName(link);
    }
    if (!creditor && link.responsibility === responsibility.creditor) {
      creditor = getLinkName(link);
    }
  });

  const textLength = 25;
  return (
    ' # ' +
    (debtor.length > textLength
      ? debtor.substring(0, textLength) + '...'
      : debtor) +
    ' # ' +
    (creditor.length > textLength
      ? creditor.substring(0, textLength) + '...'
      : creditor)
  );
};

export function useDebtAutocomplete() {
  const searchRequest = (abortSignal, value) => {
    getDebt(value, {
      signal: abortSignal.signal,
    })
      .then((_debts) => {
        debts.push(
          ..._debts.map((debt) => {
            return {
              title: debt.ref_num + buildLinksName(debt.links),
              value: debt.debt_id,
            };
          })
        );
      })
      .finally(() => (loadingDebts.value = false));
  };

  const {
    modelValue: debtModel,
    itemSearch: debtSearch,
    items: debts,
    loading: loadingDebts,
    noDataText: noDebtDataText,
  } = useDebounceRequest(searchRequest);

  return { debtModel, debts, debtSearch, loadingDebts, noDebtDataText };
}
