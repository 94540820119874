<template>
  <v-container>
    <v-form>
      <v-row>
        <v-col v-for="template in templates" :key="template.id" cols="6">
          <v-checkbox
            :value="true"
            :model-value="subscriptions.includes(template.id)"
            :label="template.display_name"
            :disabled="loading.includes(template.id)"
            @click="toggleSubscription(template.id)"
          />
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script setup>
import {
  getSubscriptionsForSection,
  getTemplatesForSection,
  subscribe,
  unsubscribe,
} from '@/services/api/notification.js';
import { ref } from 'vue';

const { systemId, section } = defineProps({
  systemId: {
    type: Number,
    required: true,
  },
  section: {
    type: String,
    required: true,
  },
});

const templates = ref([]);
const subscriptions = ref([]);
const loading = ref([]);

/**
 * @param {number} id
 * @returns {Promise<void>}
 */
function toggleSubscription(id) {
  const index = subscriptions.value.indexOf(id);
  loading.value.push(id);

  const request = {
    system_id: systemId,
    notification_template_id: id,
  };

  if (index === -1) {
    return subscribe(request).then(() => {
      const index = subscriptions.value.indexOf(id);
      index === -1 && subscriptions.value.push(id);
      loading.value.splice(loading.value.indexOf(id), 1);
    });
  }

  return unsubscribe(request).then(() => {
    const index = subscriptions.value.indexOf(id);
    index !== -1 && subscriptions.value.splice(index, 1);
    loading.value.splice(loading.value.indexOf(id), 1);
  });
}

getTemplatesForSection(section).then((lists) => {
  templates.value = lists;
});

getSubscriptionsForSection(systemId).then((subs) => {
  subscriptions.value = subs;
});
</script>
