import { getStorage } from '@/services/api/storage.js';
import { reactive } from 'vue';

const storage = reactive({});

const fetchStorage = (impoundId) => {
  return getStorage(impoundId).then((_storage) => {
    Object.assign(storage, _storage);

    return _storage;
  });
};

export { storage, fetchStorage };
