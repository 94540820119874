<template>
  <BaseModal v-model="dialog" size="lg" title="Manage notifications">
    <template #activator="{ props: propsDialog }">
      <v-tooltip location="bottom" text="Manage notifications">
        <template #activator="{ props: propsTooltip }">
          <v-btn
            color="primary"
            variant="flat"
            v-bind="{ ...propsDialog, ...propsTooltip }"
          >
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>

    <v-card-text>
      <SubscriptionForm :system-id="systemId" :section="section" />
    </v-card-text>
  </BaseModal>
</template>

<script setup>
import BaseModal from '@/components/general/BaseModal.vue';
import SubscriptionForm from '@/components/form/notification/SubscriptionForm.vue';
import { ref } from 'vue';

const dialog = ref(false);

const { systemId, section } = defineProps({
  systemId: {
    type: Number,
    required: true,
  },
  section: {
    type: String,
    required: true,
  },
});
</script>
