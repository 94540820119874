import axios from '../../plugins/axios.js';

/**
 * @param {number} id
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getRepossession = (id, request, config = undefined) => {
  return axios
    .get(route('api.v1.repossessions.show', [id]), request, config)
    .then((response) => response.data.data);
};

/**
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getRepossessions = (request, config = undefined) => {
  return axios
    .get(route('api.v1.repossessions.index'), { ...config, params: request })
    .then((response) => response.data);
};

/**
 * @param {number} id
 * @param {('debtor'|'creditor')} res_type
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getLinks = (id, res_type, config = undefined) => {
  return axios
    .get(route('api.v1.repossessions.links', [id, res_type]), config)
    .then(({ data }) => data.links);
};

/**
 * @param {number} repossession_link_id
 * @param {('up'|'down')} direction
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const sortLinks = (
  repossession_link_id,
  direction,
  config = undefined
) => {
  return axios
    .post(
      route('api.v1.repossessions.links.sort'),
      {
        repossession_link_id,
        direction,
      },
      config
    )
    .then(({ data }) => data.data);
};
