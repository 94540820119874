import axios from '../../plugins/axios.js';

/**
 * @param {object} routeData
 * @param {string} routeData.section
 * @param {number} routeData.sectionId
 * @param {string|undefined} routeData.child
 * @param {number|undefined} routeData.childId
 * @param {object} request
 * @param {import('axios').AxiosRequestConfig} config
 * @returns {Promise<object>}
 */
export const getAttachments = (
  { section, sectionId, child, childId },
  request,
  config = undefined
) => {
  const url = !childId
    ? route(`api.v1.${section}.files.index`, [sectionId])
    : route(`api.v1.${section}.${child}.files.index`, [sectionId, childId]);

  return axios.get(url, request, config).then((response) => response.data.data);
};
