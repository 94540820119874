<template>
  <BaseModal v-model="model" size="xl" title="Upload">
    <template #activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn
          v-tooltip="'Upload file'"
          v-bind="props"
          color="green"
          variant="text"
          icon="mdi-upload"
        />
      </slot>
    </template>

    <v-card-text>
      <UploadForm
        v-model:loading="loading"
        :url="url"
        @uploaded="(model = false), $emit('uploaded', $event)"
      />
    </v-card-text>
  </BaseModal>
</template>

<script setup>
import UploadForm from '@/components/form/general/UploadForm.vue';
import BaseModal from '@/components/general/BaseModal.vue';

defineEmits(['uploaded']);

defineProps({
  url: {
    type: String,
    required: true,
  },
});

const model = defineModel('model', {
  type: Boolean,
  default: false,
});

const loading = defineModel('loading', {
  type: Boolean,
  default: false,
});
</script>
