<template>
  <v-form @submit.prevent="createUser">
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="user.password"
          label="Password"
          type="password"
          :error-messages="errors.password"
          clearable
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="user.password_confirmation"
          label="Password confirmation"
          type="password"
          :error-messages="errors.password_confirmation"
          clearable
        />
      </v-col>

      <v-col cols="12">
        <v-btn
          color="green"
          block
          type="submit"
          :disabled="loading"
          :loading="loading"
        >
          Update
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { changePassword } from '@/services/api/user.js';

export default {
  components: {},
  props: {
    userId: {
      type: Number,
      default: null,
    },
    modelLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: { 'update:modelLoading': Boolean, updated: null },
  data: () => ({
    user: {
      password: null,
      password_confirmation: null,
    },
    errors: {},
  }),
  computed: {
    loading: {
      get() {
        return this.modelLoading;
      },
      set(newValue) {
        this.$emit('update:modelLoading', newValue);
      },
    },
  },
  methods: {
    createUser() {
      this.loadingForm = true;
      this.errors = {};

      changePassword(this.userId, this.user)
        .then(() => {
          this.$emit('updated');
        })
        .catch(({ response }) => {
          if (response.status === 422) {
            this.errors = response.data.errors;
            this.errorMessage = response.data.message;
            setTimeout(() => {
              this.errorMessage = null;
            }, 5000);
          }
        });
    },
  },
};
</script>
