import axios from '../../plugins/axios.js';

export const getPersonAndDepartment = (search, config = undefined) => {
  return axios
    .get('/api/v1/autocomplete/person-and-department', {
      ...config,
      params: { search },
    })
    .then((response) => {
      return response.data;
    });
};

/**
 * @param {string|object} search
 * @param {string} search.search
 * @param {number} [search.id]
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getEmployee = (search, config = undefined) => {
  search = typeof search === 'string' ? { search } : search;

  return axios
    .get('/api/v1/autocomplete/employee', { ...config, params: search })
    .then((response) => {
      return response.data.data;
    });
};

export const getRole = (search, config = undefined) => {
  return axios
    .get('/api/v1/autocomplete/role', { ...config, params: { search } })
    .then((response) => {
      return response.data.data;
    });
};

export const getDepartment = (search, config = undefined) => {
  return axios
    .get('/api/v1/autocomplete/department', { ...config, params: { search } })
    .then((response) => {
      return response.data.data;
    });
};

export const getDebt = (search, config = undefined) => {
  return axios
    .get('/api/v1/autocomplete/debt', { ...config, params: { search } })
    .then((response) => {
      return response.data.data;
    });
};

export const getPerson = (search, config = undefined) => {
  search = typeof search === 'string' ? { search } : search;

  return axios
    .get('/api/v1/autocomplete/person', { ...config, params: search })
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @param {number} department_id
 * @param {object} search
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getDepartmentAuthorizer = (
  department_id,
  search,
  config = undefined
) => {
  return axios
    .get(`/api/v1/autocomplete/department/${department_id}/authorizer`, {
      ...config,
      params: { search },
    })
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @param {object} search
 * @param {string} search.search
 * @param {number} [search.id]
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getService = (search, config = undefined) => {
  search = typeof search === 'string' ? { search } : search;

  return axios
    .get('/api/v1/autocomplete/service', { ...config, params: search })
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @param {object} search
 * @param {string} search.search
 * @param {number} [search.id]
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getStreet = (search, config = undefined) => {
  search = typeof search === 'string' ? { search } : search;

  return axios
    .get('/api/v1/autocomplete/street', { ...config, params: search })
    .then((response) => {
      return response.data.data;
    });
};

/**
 * @param {object} search
 * @param {string} search.search
 * @param {number} [search.id]
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getVehicle = (search, config = undefined) => {
  search = typeof search === 'string' ? { search } : search;

  return axios
    .get('/api/v1/autocomplete/vehicle', { ...config, params: search })
    .then((response) => {
      return response.data.data;
    });
};
