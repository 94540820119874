<template>
  <div class="dropdown">
    <div class="form-inline my-lg-0 my-2 mr-2">
      <input
        id="navbar-search"
        class="form-control mr-sm-2"
        type="text"
        placeholder="Search"
        autocomplete="off"
      />
    </div>
    <ul
      id="dropdownMenuLink"
      class="dropdown-menu"
      style="position: fixed; left: auto"
    >
      <div id="navbar-result"></div>
    </ul>
  </div>
</template>
