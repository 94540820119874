<template>
  <BaseModal v-model="dialog" size="lg" title="Manage bulk notifications">
    <template #activator="{ props: propsDialog }">
      <v-tooltip location="bottom" text="Manage bulk notifications">
        <template #activator="{ props: propsTooltip }">
          <v-btn
            color="blue"
            variant="text"
            v-bind="{ ...propsDialog, ...propsTooltip }"
            size="small"
            icon="mdi-bell"
          />
        </template>
      </v-tooltip>
    </template>

    <v-card-text>
      <BulkSubscriptionForm />
    </v-card-text>
  </BaseModal>
</template>

<script setup>
import BaseModal from '@/components/general/BaseModal.vue';
import BulkSubscriptionForm from '@/components/form/notification/BulkSubscriptionForm.vue';
import { ref } from 'vue';

const dialog = ref(false);
</script>
