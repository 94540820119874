<template>
  <v-card>
    <VDataTableServer
      v-model:items-per-page="itemsPerPage"
      v-model:page="page"
      v-model:sort-by="sortBy"
      :items-per-page-options="itemsPerPageOptions"
      :headers="headers"
      :items="roles"
      :items-length="totalRoles"
      :loading="loading"
      height="70vh"
      fixed-footer
      fixed-header
      @update:options="loadRoles"
    >
      <template #top>
        <v-card-title>
          <v-row>
            <v-col cols="12" md="6">Roles</v-col>
            <v-col cols="12" md="6" class="text-right">
              <!--
              <BaseModal v-model="addRoleModal">
                <template #activator="{ props }">
                  <v-btn color="success" v-bind="props">
                    <v-icon icon="mdi-plus" left size="small" />
                    Add role
                  </v-btn>
                </template>
                <RoleForm />
              </BaseModal>
              -->

              <v-btn
                id="btn-role"
                size="small"
                color="success"
                data-ajax-type="new"
              >
                <v-icon icon="mdi-plus" left />
                Add role
              </v-btn>

              <v-btn size="small" color="blue" @click="resetSearch">
                Search
              </v-btn>
            </v-col>
          </v-row>

          <v-form @submit.prevent="resetSearch">
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field v-model="search.name" label="Name" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-title>
      </template>

      <template #[`item.permissions`]="{ item }">
        {{ item.permissions.length }}
      </template>

      <template #[`item.is_employee`]="{ item }">
        <v-icon
          v-if="item.permissions.includes('user.is_employee')"
          color="success"
          icon="mdi-check-bold"
        />
        <v-icon v-else color="error" icon="mdi-close-thick" />
      </template>

      <template #[`item.is_client`]="{ item }">
        <v-icon
          v-if="item.permissions.includes('user.is_client')"
          color="success"
          icon="mdi-check-bold"
        />
        <v-icon v-else color="error" icon="mdi-close-thick" />
      </template>

      <template #[`item.actions`]="{ item }">
        <v-menu>
          <template #activator="{ props }">
            <v-btn icon="mdi-dots-vertical" v-bind="props" variant="text" />
          </template>

          <v-list>
            <v-list-item>
              <v-btn
                id="btn-role"
                block
                variant="text"
                data-ajax-type="edit"
                :data-role-id="item.id"
              >
                Edit
              </v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn
                id="btn-delete-modal"
                block
                variant="text"
                delelement="rol"
                :elementid="item.id"
              >
                Remove
              </v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </VDataTableServer>
  </v-card>
</template>

<script>
import RoleForm from '@/components/form/user/RoleForm.vue';
import BaseModal from '@/components/general/BaseModal.vue';
import { getRoles } from '@/services/api/user.js';

export default {
  components: {
    RoleForm,
    BaseModal,
  },
  data: () => ({
    search: {
      name: null,
    },
    roles: [],
    addRoleModal: false,
    loading: false,
    itemsPerPage: 10,
    itemsPerPageOptions: [
      { value: 10, title: '10' },
      { value: 25, title: '25' },
      { value: 50, title: '50' },
    ],
    totalRoles: 0,
    page: 1,
    sortBy: [],
    headers: [
      { title: 'ID', key: 'id' },
      { title: 'Name', key: 'name' },
      { title: 'Permissions', key: 'permissions', sortable: false },
      { title: 'Users', key: 'user_count', sortable: false },
      { title: 'Client', key: 'is_client', sortable: false },
      { title: 'Employee', key: 'is_employee', sortable: false },
      { title: 'Actions', key: 'actions', sortable: false, align: 'end' },
    ],
  }),
  mounted() {
    this.loadRoles({
      page: this.page,
      itemsPerPage: this.itemsPerPage,
      sortBy: [],
    });
  },
  methods: {
    resetSearch() {
      this.page = 0;
      this.loadRoles();
    },
    editRoleBtn(id) {
      console.log('editRoleBtn', id);
    },
    removeRoleBtn(id) {
      console.log('removeRoleBtn', id);
    },
    createRole() {
      console.log('createRole');
    },
    loadRoles() {
      if (this.loading) return;

      this.loading = true;
      this.totalRoles = 0;
      this.roles = [];
      this.search.page = this.page || 1;
      this.search.per_page = this.itemsPerPage;
      this.search.sortBy = this.sortBy;

      getRoles(this.search)
        .then((pagination) => {
          this.page = pagination.meta.current_page;
          this.roles = pagination.data;
          this.totalRoles = pagination.meta.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>
