<template>
  <v-form @submit.prevent="submit">
    <v-row>
      <v-alert
        v-if="errorMessage"
        class="mb-2"
        :text="errorMessage"
        type="error"
      />

      <v-col cols="12">
        <v-autocomplete
          v-bind="personAttrs"
          :error-messages="errors.person_id"
          label="Person"
          clearable
          v-on="personListeners"
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="form.username"
          label="Username"
          type="text"
          :error-messages="errors.username"
          clearable
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="form.email"
          label="Email"
          type="email"
          :error-messages="errors.email"
          clearable
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="form.password"
          label="Password"
          type="password"
          :error-messages="errors.password"
          clearable
        />
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="form.password_confirmation"
          label="Password confirmation"
          type="password"
          :error-messages="errors.password_confirmation"
          clearable
        />
      </v-col>

      <v-col cols="12">
        <v-btn color="green" block type="submit"> Save </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import { usePersonAutocomplete } from '@/composables/autocomplete/person-autocomplete.js';
import { createUser } from '@/services/api/user.js';
import { ref } from 'vue';
import { useErrorsForm } from '@/composables/form/errorsForm';

defineProps({
  userId: {
    type: Number,
    default: null,
  },
  modelLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['created']);

const form = ref({
  person_id: null,
  username: null,
  email: null,
  password: null,
  password_confirmation: null,
});

const loading = defineModel('loading', {
  type: Boolean,
  default: false,
});

const { errors, errorMessage, setErrorsFromResponse, clearErrors } =
  useErrorsForm();

const { attrs: personAttrs, listeners: personListeners } =
  usePersonAutocomplete();

function submit() {
  clearErrors();
  form.value.person_id = personAttrs.modelValue;
  loading.value = true;

  createUser(form.value)
    .then((user) => {
      emit('created', user);
    })
    .catch((e) => {
      if (setErrorsFromResponse(e)) {
        return;
      }

      throw e;
    })
    .finally(() => {
      loading.value = false;
    });
}
</script>
