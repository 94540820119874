<template>
  <v-menu v-model="open" :close-on-content-click="false" eager>
    <template #activator="{ props }">
      <v-btn v-bind="props" icon>
        <v-badge
          v-model="hasNotifications"
          :content="notificationCounter"
          color="red"
          max="99"
        >
          <v-icon icon="mdi-bell"></v-icon>
        </v-badge>
      </v-btn>
    </template>

    <v-card min-width="300" max-height="400">
      <v-list>
        <v-infinite-scroll v-bind="listAttrs" v-on="listListeners">
          <v-list-item
            v-for="notification in listAttrs.items"
            :key="notification.id"
            :href="notification.url"
          >
            <div
              :id="'notification_' + notification.id"
              class="mb-1 border"
              :class="{
                'text-reset': notification.status !== 1,
                'font-weight-bold': notification.status === 1,
                'border-primary': notification.status < 3,
              }"
            >
              <div class="p-1">
                <div class="notification-title">{{ notification.title }}</div>
                <small class="notification-body">{{ notification.body }}</small>
                <hr class="my-1" />
                <div class="notification-footer">
                  <small class="mr-5">{{
                    formatDate(notification.created_at, 'fullDateTime')
                  }}</small>
                  <small>{{ notification.sender_name }}</small>
                </div>
                <input
                  v-if="notification.status == 1"
                  type="hidden"
                  name="notification_{{ notification.id }}"
                  value="{{ notification.id }}"
                />
              </div>
            </div>
          </v-list-item>
        </v-infinite-scroll>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script setup>
import useInfinityScrollList from '@/composables/table/list-pagination';
import { format as formatDate } from '@/utils/formatter/date.js';
import {
  getNotifications,
  markNotificationAsSeen,
  getUnseenCounter,
} from '@/services/api/notification.js';
import { ref, watch } from 'vue';
import { computed } from 'vue';

const open = ref(false);
const notificationCounter = ref(0);
const hasNotifications = computed(() => notificationCounter.value > 0);

const { attrs: listAttrs, listeners: listListeners } = useInfinityScrollList({
  fetchRequest: getNotifications,
  afterFetch: (pagination) => {
    notificationCounter.value = pagination.meta.unseen || 0;
  },
});

let lastTimeout = null;

const timeoutMarkAsSeen = (value) => {
  if (value) {
    lastTimeout = setTimeout(() => {
      if (open.value) {
        let ids = listAttrs.items
          .filter((notification) => notification.status === 1)
          .map((notification) => notification.id);

        markNotificationAsSeen(ids).then((ids) => {
          listAttrs.items.forEach((notification) => {
            if (ids.includes(notification.id)) {
              notification.status = 2;
            }
          });

          getUnseenCounter().then((counter) => {
            notificationCounter.value = counter;
          });
        });
      }
    }, 3000);
  } else {
    clearTimeout(lastTimeout);
  }
};

watch(listAttrs.items, timeoutMarkAsSeen);
watch(open, timeoutMarkAsSeen);
</script>
