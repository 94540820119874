import { AxiosError } from 'axios';
import { computed, reactive, ref } from 'vue';

/**
 * @typedef {{[key: string]: Array<string>}} LaravelInputError
 */

/**
 * @typedef {{errors: LaravelInputError[], message: string}} LaravelErrorResponse
 */

export const useErrorsForm = () => {
  /** @type {import('vue').UnwrapNestedRefs<LaravelInputError[]>} */
  const errors = reactive({});
  /** @type {import('vue').ComputedRef<boolean>} */
  const hasError = computed(() => Object.keys(errors.value).length > 0);
  /** @type {import('vue').Ref<string|null>} */
  const errorMessage = ref(null);

  /**
   * @param {AxiosError<LaravelErrorResponse>} response
   * @returns {boolean} if the response has errors
   */
  function setErrorsFromResponse({ response }) {
    if (response?.status === 422) {
      Object.assign(errors, response.data.errors);
      errorMessage.value = response.data.message;

      setTimeout(() => {
        errorMessage.value = null;
      }, 5000);

      return true;
    }

    return false;
  }

  function clearErrors() {
    Object.keys(errors).forEach((key) => {
      delete errors[key];
    });
  }

  return {
    errors,
    errorMessage,
    hasError,
    setErrorsFromResponse,
    clearErrors,
  };
};
