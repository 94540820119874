export default [
  { value: 1, abbr: 'Actief', title: 'Actief' },
  {
    value: 2,
    abbr: 'Bij crediteur',
    title: 'Crediteur procedeert zelf verder',
  },
  { value: 3, abbr: 'Afgeschreven', title: 'Afgeschreven door crediteur' },
  { value: 4, abbr: 'Regeling', title: 'Regeling getroffen met crediteur' },
  { value: 5, abbr: 'On-Hold RSI', title: 'Aangehouden door RSI' },
  { value: 6, abbr: 'On-Hold Crediteur', title: 'Aangehouden door crediteur' },
  { value: 7, abbr: 'Afbetaald', title: 'Schuld afbetaald' },
  { value: 8, abbr: 'Nieuw', title: 'Nieuw' },
];
