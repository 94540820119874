import { useDebounceRequest } from './debounce-request.js';
import { getStreet } from '../../services/api/search.js';

/**
 * @param {import('./debounce-request.js').DebounceConfig} [config]
 * @returns {object}
 */
export function useStreetAutocomplete(config = {}) {
  /** @type {import('./debounce-request.js').searchRequest} */
  const searchRequest = (abortSignal, search, id) => {
    const axiosConfig = {
      signal: abortSignal.signal,
    };

    getStreet({ search, id }, axiosConfig)
      .then((_streets) => {
        streets.push(
          ..._streets.map((street) => {
            return {
              title: street.name + ', ' + street.city.name,
              value: Number(street.id),
            };
          })
        );
      })
      .finally(() => (loadingStreets.value = false));
  };

  const {
    attrs,
    listeners,

    modelValue: streetModel,
    itemSearch: streetSearch,
    items: streets,
    loading: loadingStreets,
    noDataText: noStreetDataText,
  } = useDebounceRequest(searchRequest, config);

  return {
    attrs,
    listeners,

    streetModel,
    streets,
    streetSearch,
    loadingStreets,
    noStreetDataText,
  };
}
