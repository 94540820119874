/**
 * @type {{[key: string]: Intl.DateTimeFormatOptions}} options
 */
const options = {
  datetime: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  date: {
    year: 'numeric',
    month: '',
    day: 'numeric',
  },
  time: {
    hour: 'numeric',
    minute: 'numeric',
  },
  fullDate: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  fullDateTime: {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  shortDate: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  shortDateTime: {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
};

/**
 * @param {(Date|string|null)} date
 * @param {('datetime'|'date'|'time'|'fullDate'|'fullDateTime'|'shortDate'|'shortDateTime')} [stringFormat]
 * @param {string} [tz]
 * @returns {string|null}
 */
export const format = (
  date,
  stringFormat = 'datetime',
  tz = 'America/Aruba'
) => {
  if (!date) {
    return null;
  }

  const defaultOptions = options[stringFormat];

  if (!defaultOptions) {
    console.error(`Option ${stringFormat} is not supported`);
    return null;
  }

  date = new Date(date);
  defaultOptions.timeZone = tz;

  return date.toLocaleString('en-US', defaultOptions);
};
