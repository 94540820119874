const formatMoney = (value, locale = 'nl-NL') => {
  value = parseFloat(value);

  if (!value) {
    value = 0;
  }

  return value.toLocaleString(locale, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export { formatMoney };
