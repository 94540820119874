import axios from '../../plugins/axios.js';

/**
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @returns {Promise<object>}
 */
export const getTowingCompanies = (config = undefined) => {
  return axios
    .get('/api/v1/companies/towing', config)
    .then((response) => response.data.data);
};
