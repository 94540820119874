export default [
  { title: 'Unknown', value: '' },
  { title: 'Office A: Cabinet', value: 'Office A: Cabinet' },
  { title: 'Office A: Desk', value: 'Office A: Desk' },
  { title: 'Office B: Cabinet', value: 'Office B: Cabinet' },
  { title: 'Office B: Desk', value: 'Office B: Desk' },
  { title: 'Office C: Cabinet', value: 'Office C: Cabinet' },
  { title: 'Office C: Desk', value: 'Office C: Desk' },
  { title: 'Office D: Cabinet', value: 'Office D: Cabinet' },
  { title: 'Office D: Desk', value: 'Office D: Desk' },
  { title: 'Office E: Cabinet', value: 'Office E: Cabinet' },
  { title: 'Office E: Desk', value: 'Office E: Desk' },
  { title: 'Office F: Cabinet', value: 'Office F: Cabinet' },
  { title: 'Office F: Desk', value: 'Office F: Desk' },
  { title: 'Office G: Cabinet', value: 'Office G: Cabinet' },
  { title: 'Office G: Desk', value: 'Office G: Desk' },
  { title: 'Field: File crate', value: 'Field: File crate' },
];
