<template>
  <v-dialog :width="width" transition="dialog-transition">
    <template #activator="{ props }">
      <slot name="activator" :props="props">
        <v-btn v-if="button" color="success" v-bind="props">Open</v-btn>
      </slot>
    </template>
    <template #default>
      <v-card :height="height" :title="title" :loading="loading">
        <slot></slot>

        <div
          v-if="errorMessage"
          class="position-fixed"
          style="top: 20px; right: 10%; width: 80%"
        >
          <v-alert :text="errorMessage" type="error" />
        </div>

        <v-overlay
          :model-value="loading"
          class="align-center justify-center"
          contained
        >
          <v-progress-circular color="primary" indeterminate size="100" />
        </v-overlay>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup>
import { computed } from 'vue';
import { useDisplay } from 'vuetify';

const { size } = defineProps({
  title: {
    type: String,
    default: undefined,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  errorMessage: {
    type: String,
    default: null,
  },
  button: {
    type: Boolean,
    default: false,
  },
  height: {
    type: [Number, String],
    default: undefined,
  },
  size: {
    type: String,
    default: 'md',
  },
});

const sizes = {
  xs: {
    xs: { width: 'auto' },
    sm: { width: 300 },
    md: { width: 300 },
    lg: { width: 300 },
    xl: { width: 300 },
  },
  sm: {
    xs: { width: 'auto' },
    sm: { width: 400 },
    md: { width: 500 },
    lg: { width: 500 },
    xl: { width: 500 },
  },
  md: {
    xs: { width: 'auto' },
    sm: { width: 550 },
    md: { width: 600 },
    lg: { width: 600 },
    xl: { width: 600 },
  },
  lg: {
    xs: { width: 'auto' },
    sm: { width: 'auto' },
    md: { width: 600 },
    lg: { width: 800 },
    xl: { width: 950 },
  },
  xl: {
    xs: { width: 'auto' },
    sm: { width: 600 },
    md: { width: 960 },
    lg: { width: 1200 },
    xl: { width: 1280 },
  },
};

const { name: bkName } = useDisplay();

const width = computed(() => {
  let selectedSizes = sizes[size];
  if (!selectedSizes) return 'auto';

  return selectedSizes[bkName.value]?.width || 'auto';
});
</script>
