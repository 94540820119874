import { useDebounceRequest } from './debounce-request.js';
import { getService } from '../../services/api/search.js';

/**
 * @param {import('./debounce-request.js').DebounceConfig} [config]
 * @returns {object}
 */
export function useServiceAutocomplete(config = {}) {
  /** @type {import('./debounce-request.js').searchRequest} */
  const searchRequest = (abortSignal, search, extraFields) => {
    const axiosConfig = {
      signal: abortSignal.signal,
    };

    getService({ search, ...extraFields }, axiosConfig)
      .then((_services) => {
        services.push(
          ..._services.map((service) => {
            return {
              title: service.name,
              value: Number(service.id),
              price: service.price,
            };
          })
        );
      })
      .finally(() => (loadingServices.value = false));
  };

  const {
    attrs,
    listeners,

    modelValue: serviceModel,
    itemSearch: serviceSearch,
    items: services,
    loading: loadingServices,
    noDataText: noServiceDataText,
  } = useDebounceRequest(searchRequest, config);

  return {
    attrs,
    listeners,

    serviceModel,
    services,
    serviceSearch,
    loadingServices,
    noServiceDataText,
  };
}
