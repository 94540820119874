import SearchDropdown from '../plugins/legacySearchDropdown';
import buildDebtsClientTable from '../templates/tables/debt-client-list';

export default () => {
  let page = 0;

  let debtor = new SearchDropdown(
    'debt-filter-debtor-name',
    'debt-filter-debtor-name-container',
    {
      drawOptions: (that) => {
        if (that.receivedData.person) {
          that.receivedData.person.forEach((person_i) => {
            that.result_container.append(
              `<li class="dropdown-item" data-option="${that.counter}" data-person-id="${person_i.person_id}">${person_i.firstname} ${person_i.middlename} ${person_i.lastname}</li>`
            );
            that.counter++;
          });
        }
        if (that.receivedData.department) {
          that.receivedData.department.forEach((department_i) => {
            that.result_container.append(
              `<li class="dropdown-item" data-option="${that.counter}" data-department-id="${department_i.department_id}">${department_i.handle_name} [${department_i.department}]</li>`
            );
            that.counter++;
          });
        }
        return that.counter++;
      },
      selectOption: (that) => {
        let $selectedOption = $(that.result_container).find(
          `[data-option="${that.selectPosition}"]`
        );
        let personId = $selectedOption.data('personId');
        let departmentId = $selectedOption.data('departmentId');
        let $person = document.getElementById('debt-filter-debtor-person-id');
        let $department = document.getElementById(
          'debt-filter-debtor-department-id'
        );

        $person.value = personId || '';
        $department.value = departmentId || '';
        $person.dispatchEvent(new Event('change'));
        $department.dispatchEvent(new Event('change'));

        let returnData = {};
        if (personId) {
          returnData.person = Object.values(that.receivedData.person).filter(
            (person) => {
              return parseInt(personId) == parseInt(person.person_id);
            }
          );
        }

        if (departmentId) {
          returnData.department = Object.values(
            that.receivedData.department
          ).filter((department) => {
            return parseInt(departmentId) == parseInt(department.department_id);
          });
        }
        return returnData;
      },
    }
  );

  debtor.on({
    url: BASEURL + 'ajax/search',
  });

  let creditor = new SearchDropdown(
    'debt-filter-creditor-name',
    'debt-filter-creditor-name-container',
    {
      drawOptions: (that) => {
        if (that.receivedData.person) {
          that.receivedData.person.forEach((person_i) => {
            that.result_container.append(
              `<li class="dropdown-item" data-option="${that.counter}" data-person-id="${person_i.person_id}">${person_i.firstname} ${person_i.middlename} ${person_i.lastname}</li>`
            );
            that.counter++;
          });
        }
        if (that.receivedData.department) {
          that.receivedData.department.forEach((department_i) => {
            that.result_container.append(
              `<li class="dropdown-item" data-option="${that.counter}" data-department-id="${department_i.department_id}">${department_i.handle_name} [${department_i.department}]</li>`
            );
            that.counter++;
          });
        }
        return that.counter++;
      },
      selectOption: (that) => {
        let $selectedOption = $(that.result_container).find(
          `[data-option="${that.selectPosition}"]`
        );
        let personId = $selectedOption.data('personId');
        let departmentId = $selectedOption.data('departmentId');
        let $person = document.getElementById('debt-filter-creditor-person-id');
        let $department = document.getElementById(
          'debt-filter-creditor-department-id'
        );

        $person.value = personId || '';
        $department.value = departmentId || '';
        $person.dispatchEvent(new Event('change'));
        $department.dispatchEvent(new Event('change'));

        let returnData = {};
        if (personId) {
          returnData.person = Object.values(that.receivedData.person).filter(
            (person) => {
              return parseInt(personId) == parseInt(person.person_id);
            }
          );
        }

        if (departmentId) {
          returnData.department = Object.values(
            that.receivedData.department
          ).filter((department) => {
            return parseInt(departmentId) == parseInt(department.department_id);
          });
        }
        return returnData;
      },
    }
  );

  creditor.on({
    url: BASEURL + 'ajax/search',
  });

  // Scroll debt loader
  $(window).scroll(function () {
    page = document.getElementById('debts-client-filter-page-number').value;
    if (loadDebtClientFilter && page >= 2 && checkScrollBottom()) {
      loadDebtClientFilter = false;
      buildDebtsClientTable(page);
    }
  });

  toggleDebtorCreditor(
    null,
    'debt-filter-debtor-name',
    'debt-filter-creditor-name'
  );
  let debtFormFilter = document.getElementById('debts-client-data-filter');
  // first load
  const firstLoad = (page) => {
    buildDebtsClientTable(page).then(() => {
      if (loadDebtClientFilter && !checkScrollable()) {
        loadDebtClientFilter = false;
        page = document.getElementById('debts-client-filter-page-number').value;
        firstLoad(page);
      }
    });
  };
  firstLoad(page);
  debtFormFilter.addEventListener('submit', (e) => {
    e.preventDefault();
    page = 1;
    // document.getElementById("extra_cost_total").innerText = "0,00"
    // document.getElementById("pro_rata_total").innerText = "0,00"
    // document.getElementById("for_client_total").innerText = "0,00"
    firstLoad(page);
  });
};
