import { reactive } from 'vue';
/**
 * @param {string} name
 * @param {number} id
 * @returns {void}
 */
window.vueAdapter = (name, id) => {
  const $target = document.getElementById('adapter-' + name + '-' + id);
  const data = JSON.parse($target.dataset.adapter);

  let $adapter = document.getElementById('adapter-' + name);

  if (!$adapter) {
    return;
  }

  $adapter.dataset.adapter = JSON.stringify(data);

  $adapter.click();
};

/**
 * @param {object} startData
 * @param {(data: object, adapterData: import('vue').Reactive<object>) => void} cb
 * @param {string} id
 * @returns {{ adapterData: import('vue').Reactive<object>, injectData: (event: MouseEvent|Event) => void }}
 */
export default function useInjectDataAdapter(startData = {}, cb, id) {
  if (typeof startData !== 'object') {
    throw new Error('The startData argument must be an object.');
  }

  const adapterData = reactive(startData);

  /**
   * @param {MouseEvent|Event} event
   */
  function injectData(event) {
    const values = event.currentTarget.dataset.adapter;
    if (!values) {
      return;
    }

    const data = JSON.parse(values);
    cb(data, adapterData);
  }

  document
    .getElementById('adapter-' + id)
    ?.addEventListener('click', injectData);

  return { adapterData, injectData };
}
