<template>
  <PeopleLink v-if="user?.person" :person="user.person" v-bind="$attrs" />
</template>

<script>
import PeopleLink from '@/components/links/PeopleLink.vue';

export default {
  components: {
    PeopleLink,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
