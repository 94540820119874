<template>
  <v-card>
    <VDataTableServer
      v-bind="tableAttrs"
      fixed-footer
      fixed-header
      v-on="tableListeners"
    >
      <template #top>
        <v-card-title class="d-print-none">
          <v-row>
            <v-col cols="12" md="6">Vehicles</v-col>

            <v-col cols="12" md="6" class="text-right">
              <VehicleFormModal
                v-if="$gates.hasPermission('vehicle.create')"
                v-model:loading="loadingVehicleForm"
                v-model="vehicleFormDialog"
                @created="redirectToVehicle"
              />

              <v-btn
                v-tooltip="'Search'"
                variant="text"
                type="submit"
                form="vehicleFilter"
                size="small"
                color="blue"
                icon="mdi-magnify"
              />

              <v-btn
                v-tooltip="filterExpand ? 'Hide filter' : 'Show filter'"
                variant="text"
                size="small"
                icon="mdi-filter"
                :color="filterExpand ? 'orange' : 'blue'"
                @click="filterExpand = !filterExpand"
              />
            </v-col>
          </v-row>

          <v-expand-transition>
            <v-form
              v-show="filterExpand"
              id="vehicleFilter"
              @submit.prevent="fetchItems"
            >
              <v-row>
                <v-col cols="12" md="3" lg="2">
                  <v-text-field
                    v-model="search.plate_number"
                    label="Plate number"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-text-field
                    v-model="search.serial_number"
                    label="Engine/chassis number"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-model="search.make"
                    :items="makes"
                    label="Make"
                    clearable
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-model="search.model"
                    :items="filteredModels"
                    label="Model"
                    clearable
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-model="search.color"
                    :items="colors"
                    label="Color"
                    clearable
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-text-field
                    v-model="search.year"
                    type="number"
                    label="Year"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-select
                    v-model="search.sale"
                    label="Sale status"
                    :items="[
                      { title: 'Sold', value: 'sold' },
                      { title: 'For sale', value: 'for_sale' },
                      { title: 'Not for sale', value: 'not_for_sale' },
                    ]"
                  />
                </v-col>

                <v-col cols="12" md="3" lg="2">
                  <v-autocomplete
                    v-bind="ownerAttrs"
                    label="Owner"
                    clearable
                    v-on="ownerListeners"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-expand-transition>
        </v-card-title>
      </template>

      <template #[`item.plate_number`]="{ item }">
        <a
          class="text-reset font-weight-bold text-nowrap"
          :href="route('vehicles.show', [item.id])"
        >
          {{ item.plate_number || 'Not registered' }}
        </a>
      </template>

      <template #[`item.serial_number`]="{ item }">
        <span v-if="item.chassis_number" class="d-block">
          <span class="font-weight-bold">Chassis:</span>
          {{ item.chassis_number }}
        </span>
        <span v-if="item.engine_number" class="d-block">
          <span class="font-weight-bold">Engine:</span>
          {{ item.engine_number }}
        </span>
      </template>

      <template #[`item.owner`]="{ item }">
        <PeopleLink v-if="item.owner?.firstname" :person="item.owner" bold />
        <CompanyLink
          v-else-if="item.owner?.handle_name"
          :company="item.owner"
          bold
        />
        <template v-else>Not registered</template>
      </template>

      <template #[`item.sale_price`]="{ item }">
        {{ item.sale_price ? formatMoney(item.sale_price) : '-' }}
      </template>
    </VDataTableServer>
  </v-card>
</template>

<script setup>
import PeopleLink from '@/components/links/PeopleLink.vue';
import CompanyLink from '@/components/links/CompanyLink.vue';
import VehicleFormModal from '@/components/modal/vehicle/VehicleFormModal.vue';
import usePaginationTable from '@/composables/table/table-pagination.js';
import {
  getVehicles,
  getUsedVehicleMakes,
  getUsedVehicleModels,
} from '@/services/api/vehicle.js';
import { formatMoney } from '@/utils/formatter/number';
import { computed, ref, watch } from 'vue';
import { useLinkAutocomplete } from '@/composables/autocomplete/link-autocomplete';
import { getColors } from '@/store/vehicle/vehicle';

const filterExpand = ref(false);

const search = ref({
  plate_number: null,
  serial_number: null,
  make: null,
  model: null,
  color: null,
  year: null,
  owner_id: null,
  sale: null,
});

const vehicleFormDialog = ref(false);
const loadingVehicleForm = ref(false);
const makes = ref([]);
const models = ref([]);

getUsedVehicleMakes().then((_makes) => {
  makes.value = _makes;
});

getUsedVehicleModels().then((_models) => {
  models.value = _models;
});

const _colors = getColors();
const colors = computed(() =>
  _colors.value.map((color) => ({ value: color.id, title: color.name }))
);

const filteredModels = computed(() => {
  if (!search.value.make) {
    return models.value.map((model) => model.model);
  }

  return models.value
    .filter((model) => model.make == search.value.make)
    .map((model) => model.model);
});

watch(
  () => search.value.make,
  () => {
    search.value.model = null;
  }
);

const headers = [
  { title: 'Plate number', key: 'plate_number', sortable: false },
  { title: 'Engine/chassis number', key: 'serial_number', sortable: false },
  { title: 'Make', key: 'make' },
  { title: 'Model', key: 'model' },
  { title: 'Color', key: 'color' },
  { title: 'Year', key: 'year' },
  { title: 'Owner', key: 'owner', align: 'end', sortable: false },
  { title: 'Sale price', key: 'sale_price', align: 'end', sortable: false },
];

const {
  link: ownerObject,
  attrs: ownerAttrs,
  listeners: ownerListeners,
} = useLinkAutocomplete();

function updateSearch() {
  search.value.owner_person_id = null;
  search.value.owner_department_id = null;

  if (ownerObject?.type && ownerObject.id) {
    if (ownerObject?.type == 'person') {
      search.value.owner_person_id = ownerObject.id;
    } else if (ownerObject?.type == 'department') {
      search.value.owner_department_id = ownerObject.id;
    }
  }
}

const {
  attrs: tableAttrs,
  listeners: tableListeners,
  fetchItems,
} = usePaginationTable({
  headers,
  sortBy: [{ key: 'make', order: 'asc' }],
  fetchRequest: getVehicles,
  prepareSearch() {
    updateSearch();

    return {
      ...search.value,
    };
  },
});

/**
 * @param {object} vehicle
 */
function redirectToVehicle(vehicle) {
  if (!vehicle?.id) {
    console.error('Vehicle not found');

    return;
  }

  window.location.href = route('vehicles.show', [vehicle.id]);
}

fetchItems();
</script>
