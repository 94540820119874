<template>
  <v-form enctype="multipart/form-data" @submit.prevent="uploadFile">
    <v-row>
      <v-col cols="12">
        <v-alert v-if="errorMessage" type="error" class="pb-2">
          {{ errorMessage }}
        </v-alert>
      </v-col>

      <v-col cols="12" class="pb-4">
        <v-file-input
          v-model="currentFile"
          label="File"
          multiple
          :error-messages="errors.files"
          @change="pushFiles"
        />
      </v-col>

      <v-col
        cols="12"
        class="px-2 py-4 border rounded"
        style="max-height: 60vh; overflow-y: auto"
      >
        <div v-if="!files.length" class="d-flex justify-center">
          Choose a file
        </div>
        <v-row v-for="(file, index) in files" :key="index">
          <v-col v-if="index" cols="12">
            <hr class="m-0 py-2" />
          </v-col>

          <v-col cols="2" class="d-flex justify-center align-center">
            <v-img :src="file.thumbnail" height="80" />
          </v-col>

          <v-col cols="9">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="file.name"
                  label="Name"
                  :error-messages="errors['names.' + index]"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="file.description"
                  label="Description"
                  :error-messages="errors['descriptions.' + index]"
                />
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="1" class="d-flex justify-center align-center">
            <v-btn
              color="red"
              variant="tonal"
              icon="mdi-file-cancel"
              @click="files.splice(index, 1)"
            />
          </v-col>

          <v-col
            v-if="errors['files.' + index]"
            cols="12"
            class="d-flex justify-center align-center"
          >
            <small class="text-danger">
              {{ errors['files.' + index][0] }}
            </small>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" class="pt-2">
        <v-btn type="submit" color="green" block>Upload</v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script setup>
import { useErrorsForm } from '@/composables/form/errorsForm';
import axios from 'axios';
import { ref } from 'vue';

const emit = defineEmits(['uploaded', 'error']);

const { url } = defineProps({
  url: {
    type: String,
    required: true,
  },
});

const currentFile = ref(null);
const files = ref([]);

const { errors, errorMessage, setErrorsFromResponse, clearErrors } =
  useErrorsForm();

/**
 *
 * @param {File} file
 */
function pushFile(file) {
  files.value.push({
    name: file.name,
    description: '',
    file: file,
    thumbnail: URL.createObjectURL(file),
  });

  file.value = null;
}

function pushFiles() {
  if (!currentFile.value) return;

  if (currentFile.value.length) {
    currentFile.value.forEach(pushFile);
  } else {
    pushFile(currentFile.value);
  }

  currentFile.value = null;
}

function uploadFile() {
  clearErrors();
  const formData = new FormData();

  files.value.forEach((file) => {
    formData.append('files[]', file.file);
    formData.append('names[]', file.name);
    formData.append('descriptions[]', file.description);
  });

  axios
    .post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((response) => {
      emit('uploaded', response.data);
    })
    .catch((e) => {
      if (setErrorsFromResponse(e)) {
        emit('error', e);
        return;
      }

      throw e;
    });
}
</script>
