import axios from '../../plugins/axios.js';

/**
 * @typedef {Object} ReasonRequestObject
 * @property {number|undefined} reason_id
 * @property {boolean|undefined} rental
 * @property {boolean|undefined} debt
 * @property {boolean|undefined} dedicated
 * @property {boolean|undefined} impound
 */

/**
 * @param {ReasonRequestObject} search
 * @param {import('axios').AxiosRequestConfig|undefined} config
 * @return {Promise<Object>}
 */
export const getReasons = (search, config = undefined) => {
  return axios
    .get(`/api/v1/reasons`, {
      ...config,
      params: search,
    })
    .then((response) => response.data.data);
};
