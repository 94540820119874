<template>
  <v-card>
    <v-card-title class="text-center">
      <span class="float-left d-print-none">
        <v-btn
          v-can="'storage.invoice.create'"
          v-tooltip="'Bill additional costs'"
          :data-storage-id="storage.id"
          data-target="btn-invoice"
          data-ajax-type="newSE"
          icon="mdi-receipt-text-plus"
          variant="text"
        />
      </span>

      Storage costs

      <span class="float-right d-print-none">
        <v-btn
          v-can="'storage.cost.create'"
          v-tooltip="'Add cost'"
          :data-storage-id="storage.id"
          data-target="btn-storage-cost"
          data-ajax-type="new"
          icon="mdi-cash-plus"
          variant="text"
        />
      </span>
    </v-card-title>

    <v-card-text v-if="costs.length">
      <DeleteButton
        v-if="deleteUrl"
        v-model="deleteModal"
        :url="deleteUrl"
        no-button
        @deleted="fetchCosts"
      />

      <v-table density="compact">
        <thead>
          <tr>
            <th>Description</th>
            <th class="text-right">Amount</th>
            <th
              v-can:any="'storage.cost.update|storage.cost.delete'"
              class="text-right d-print-none"
            >
              Options
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="cost in costs" :key="cost.id">
            <td>
              <span>{{ cost.description }}</span>
              <v-icon
                v-if="cost.note"
                v-tooltip="cost.note"
                color="grey"
                class="ml-1"
                icon="mdi-message-text"
              />
            </td>

            <td class="text-right d-print-none">
              {{ formatMoney(cost.amount) }}
            </td>

            <td
              v-can:any="'storage.cost.update|storage.cost.delete'"
              class="text-right"
            >
              <v-menu>
                <template #activator="{ props: aProps }">
                  <v-btn
                    icon="mdi-dots-vertical"
                    v-bind="aProps"
                    variant="text"
                  />
                </template>

                <v-list>
                  <v-list-item v-can="'storage.cost.update'">
                    <v-btn
                      :data-cost-id="cost.cost_id"
                      data-target="btn-storage-cost"
                      data-ajax-type="edit"
                      variant="text"
                      color="blue"
                      text="Edit"
                      block
                    />
                  </v-list-item>

                  <v-list-item v-can="'storage.cost.delete'">
                    <v-btn
                      color="red"
                      variant="text"
                      text="Delete"
                      block
                      @click="deleteCost(cost.id)"
                    />
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { storage } from '@/store/storage/storage.js';
import { getStorageCosts } from '@/services/api/storage.js';
import { computed, ref, watch } from 'vue';
import { formatMoney } from '@/utils/formatter/number.js';
import DeleteButton from '@/components/general/DeleteButton.vue';

const costs = ref([]);
const selectedCost = ref(null);
const deleteModal = ref(false);
watch(storage, fetchCosts);

const deleteUrl = computed(() => {
  if (!selectedCost.value || !storage.id) {
    return null;
  }

  return route('api.v1.storages.costs.destroy', [
    storage.id,
    selectedCost.value?.id,
  ]);
});

function fetchCosts() {
  if (!storage.id) {
    setTimeout(() => {
      if (!storage.id) {
        console.error('Storage ID is not set');
      }
    }, 1000);

    return;
  }

  getStorageCosts(storage.id).then((_costs) => {
    costs.value = _costs;
  });
}

/**
 * @param {number} costId
 */
function deleteCost(costId) {
  selectedCost.value = costs.value.find((cost) => cost.id === costId);

  console.log(selectedCost.value);
  if (selectedCost.value) {
    deleteModal.value = true;
  }
}

fetchCosts();
</script>
