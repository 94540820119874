import { reactive, ref } from 'vue';

/**
 * @param {object} config
 * @param {Function} config.fetchRequest
 * @param {Function} [config.prepareSearch]
 * @param {boolean} [config.clearItems]
 * @param {number} [config.itemsPerPage]
 * @returns {object}
 */
export default function useInfinityScrollList(config = {}) {
  if (!config.fetchRequest) {
    throw new Error('fetchRequest is required');
  }

  const items = ref([]);
  const itemsPerPage = config.itemsPerPage || 5;
  const page = ref(1);

  /**
   *
   * @param {Function|undefined} done
   */
  function fetchItems(done) {
    if (config.clearItems) {
      items.value = [];
    }

    const search = config.prepareSearch ? config.prepareSearch() : {};
    search.page = page.value || 1;
    search.per_page = itemsPerPage;

    config.fetchRequest(search).then((pagination) => {
      page.value = pagination.meta.current_page || pagination.current_page;

      items.value.push(...pagination.data);

      if (config.afterFetch) {
        config.afterFetch(pagination);
      }

      if (!done) {
        return;
      }

      pagination.data.length ? done('ok') : done('empty');
    });
  }

  fetchItems();

  return {
    fetchItems,

    attrs: reactive({
      items,
    }),

    listeners: {
      load: ({ done }) => {
        done('loading');
        page.value++;
        fetchItems(done);
      },
    },
  };
}
