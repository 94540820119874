<template>
  <BaseModal v-model="dialog" size="sm" title="Change password">
    <template #activator="{ props: propsDialog }">
      <v-tooltip location="bottom" text="Change password">
        <template #activator="{ props: propsTooltip }">
          <v-btn
            color="blue"
            variant="text"
            v-bind="{ ...propsDialog, ...propsTooltip }"
            size="small"
            icon="mdi-lock-reset"
          />
        </template>
      </v-tooltip>
    </template>

    <v-card-text>
      <ChangePasswordForm
        v-model:modelLoading="loadingForm"
        :user-id="userId"
        @updated="passwordUpdated"
      />
    </v-card-text>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/general/BaseModal.vue';
import ChangePasswordForm from '@/components/form/user/ChangePasswordForm.vue';

export default {
  components: {
    BaseModal,
    ChangePasswordForm,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    loadingForm: false,
  }),
  methods: {
    passwordUpdated() {
      this.dialog = false;
      location.reload();
    },
  },
};
</script>
