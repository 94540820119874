import { useDebounceRequest } from './debounce-request.js';
import { getRole } from '../../services/api/search.js';

export function useRoleAutocomplete() {
  const searchRequest = (abortSignal, value) => {
    getRole(value, {
      signal: abortSignal.signal,
    })
      .then((_roles) => {
        roles.push(
          ..._roles.map((role) => {
            return {
              title: role.name,
              value: Number(role.id),
            };
          })
        );
      })
      .finally(() => (loadingRoles.value = false));
  };

  const {
    modelValue: roleModel,
    itemSearch: roleSearch,
    items: roles,
    loading: loadingRoles,
    noDataText: noRoleDataText,
  } = useDebounceRequest(searchRequest);

  return { roleModel, roles, roleSearch, loadingRoles, noRoleDataText };
}
