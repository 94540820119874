import $ from 'jquery';
import 'popper.js';
import 'bootstrap';
// import 'vuetify/styles';
import SearchDropdown from './legacy/plugins/SearchDropdown';
import legacySearchDropdown from './legacy/plugins/legacySearchDropdown';

window.$ = $;

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

import axios from './plugins/axios.js';
window.axios = axios;
window.SearchDropdown = SearchDropdown;
window.searchDropdown = legacySearchDropdown;

// LEGACY
import {
  show,
  hide,
  emptyHTMLContent,
} from './legacy/functions/html-handler.js';
import { moneyFormat } from './legacy/functions/filters.js';
import runClientTable from './legacy/tables/client-table.js';
import {
  checkScrollable,
  checkScrollBottom,
} from './legacy/functions/scrollable.js';
import { covertObjectToArray } from './legacy/functions/data-structure';
import {
  companyLink,
  legacyDepartmentLink,
  personLink,
  departmentLink,
} from './templates/general/links.js';
import {
  debtorsInfo,
  creditorsInfo,
  calculateTotalPaymentPercentage,
} from './legacy/functions/debt-functions.js';

window.show = show;
window.hide = hide;
window.emptyHTMLContent = emptyHTMLContent;
window.moneyFormat = moneyFormat;
window.checkScrollable = checkScrollable;
window.checkScrollBottom = checkScrollBottom;
window.covertObjectToArray = covertObjectToArray;
window.runClientTable = runClientTable;

window.calculateTotalPaymentPercentage = calculateTotalPaymentPercentage;
window.RIPGlobalFunctions = {
  templates: {
    debtorsInfo,
    creditorsInfo,
    personLink,
    departmentLink: legacyDepartmentLink,
    newDepartmentLink: departmentLink,
    companyLink,
  },
  builders: {},
};
