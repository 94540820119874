<template>
  <BaseModal
    v-model="dialog"
    :loading="loadingForm"
    :title="isCreateMode ? 'Nieuwe casus' : 'Casus bijwerken'"
    size="xl"
  >
    <template #activator="{ props }">
      <v-btn v-if="isEditMode" color="primary" v-bind="props">
        <i class="fas fa-pen"></i>
      </v-btn>
      <v-btn v-if="isCreateMode" color="success" v-bind="props">
        Add case
      </v-btn>
    </template>

    <DebtForm
      v-model:loading="loadingForm"
      :debt-id="debtId"
      :rental="rental"
      :person-id="personId"
      :department-id="departmentId"
    />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/general/BaseModal.vue';
import DebtForm from '@/components/form/debt/DebtForm.vue';

export default {
  components: {
    BaseModal,
    DebtForm,
  },
  props: {
    debtId: {
      type: [Number, String],
      default: null,
    },
    rental: {
      type: Boolean,
      default: null,
      required: false,
    },
    personId: {
      type: [Number, String],
      default: null,
    },
    departmentId: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    loadingForm: false,
  }),
  computed: {
    isCreateMode() {
      return !this.debtId;
    },
    isEditMode() {
      return !!this.debtId;
    },
  },
};
</script>
