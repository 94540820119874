const checkScrollBottom = (offset = 5) => {
  return (
    document.documentElement.scrollTop >
    document.body.scrollHeight - window.innerHeight - offset
  );
};

const checkScrollable = (offset = 0) => {
  return document.body.scrollHeight > window.innerHeight + offset;
};

export { checkScrollBottom, checkScrollable };
