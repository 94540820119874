/**
 * @param {CallableFunction} callback
 * @param {Number} wait
 * @param {Boolean} immediate
 * @returns
 */
function debounce(callback, wait, immediate = false) {
  /** @type {Number} timeout */
  var timeout;

  return function () {
    var context = this;
    var args = arguments;

    clearTimeout(timeout);

    if (immediate && !timeout) {
      callback(args, context);
    }

    timeout = setTimeout(function () {
      timeout = null;
      if (!immediate) {
        callback(args, context);
      }
    }, wait);
  };
}

/**
 * @param {Object} object
 * @param {String} path
 * @returns {any}
 */
const getFromDotNotation = (object, path) => {
  if (!object) {
    return undefined;
  }

  return path.split('.').reduce((obj, index) => {
    if (!obj) {
      return undefined;
    }

    return obj[index];
  }, object);
};

export { debounce, getFromDotNotation };
