import { getImpound } from '@/services/api/impound.js';
import { reactive } from 'vue';

const impound = reactive({});

const fetchImpound = (impoundId) => {
  return getImpound(impoundId).then((i) => {
    Object.assign(impound, i);

    return i;
  });
};

export { impound, fetchImpound };
