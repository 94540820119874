<template>
  <BaseModal v-model="dialog" :loading="loadingForm" title="Impound">
    <template #activator="{ props }">
      <v-btn color="success" v-bind="props" size="small" text="Impound" />
    </template>

    <ImpoundForm
      v-model:update="loadingForm"
      :vehicle-id="vehicleId"
      :impound-id="impoundId"
    />
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/general/BaseModal.vue';
import ImpoundForm from '@/components/form/storage/ImpoundForm.vue';

export default {
  components: {
    BaseModal,
    ImpoundForm,
  },
  props: {
    vehicleId: {
      type: [Number, String],
      default: null,
    },
    impoundId: {
      type: [Number, String],
      default: null,
    },
  },
  data: () => ({
    dialog: false,
    loadingForm: false,
  }),
};
</script>
